
import { BIconExclamationTriangleFill } from 'bootstrap-vue'

export default {
  components: {
    BIconExclamationTriangleFill
  }
  // props: {
  //   message: {
  //     type: String,
  //     required: false,
  //     default: null
  //   }
  // }
}
