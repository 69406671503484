
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import AttributeToolstipModal from '~/components/modal/attribute_toolstip.vue'
import InputAlertModal from '~/components/modal/input-alert.vue'
import NeedHelpButton from '~/components/UI/need-help-btn.vue'
import imgZoom from '~/components/modal/imgZoom.vue'
import imgSlide from '~/components/modal/imgSlide.vue'
import sampleProductPreview from '~/components/modal/sampleProductPreview.vue'
import factoryExample from '~/components/modal/factoryExample.vue'
// import otp from '~/components/modal/otp.vue'
import emailModal from '~/components/modal/email.vue'
import myDesignModal from '~/components/modal/my-designs.vue'

export default {
  components: {
    InputAlertModal,
    AttributeToolstipModal,
    imgZoom,
    imgSlide,
    sampleProductPreview,
    factoryExample,
    NeedHelpButton,
    // otp,
    emailModal,
    myDesignModal
  },
  validate ({ params }) {
    return /^\d+$/.test(params.id)
  },

  async asyncData ({ params, $axios, $replaceSEO, query }) {
    // get ข้อมูล seo เพื่อนำไปแสดงที่ head
    const seo = await $axios.get('sitemaps/products_customize')
    // get promocode ที่ active ทั้งหมดอยู่ในปัจจุบัน เพื่อนำไป filter promocode ที่เก็บไว้ใน local storage
    const promoCode = await $axios.get('get-promo-codes')
    const promoCodeData = promoCode.data
    // get last promocode เพื่อแสดงเป็น popup promocode
    const lastPromoCode = await $axios.get('get-last-promocode-active')
    let promoCodePopup = null
    if (lastPromoCode) {
      promoCodePopup = lastPromoCode.data
    }

    let sampleProductData = null
    let productData = {}
    // ดึงข้อมูล เช่น papers, sampleProducts, relatedProducts เอาไว้วนแสดงตาม section ต่างๆภายในหน้า
    const result = await $axios.$get('mapping_inputs', {
      params: { mapping_pattern_id: params.id }
    })

    let wldList = result.data.standard_sizes
    const packagingTypeId = result.data.mappingInput.packaging_type_id

    // ดึงข้้อมูลหลักของ object mapping_input ของหน้านี้ เช่น mappingInput, special_technics, emanals, paper เป็น
    const input = await $axios.$get('mapping_inputs', {
      params: { packaging_type_id: packagingTypeId }
    })

    // ดึงข้อมูลแสดงตัวอย่าง preview รูป ตรง section ที่มี ราคาต่อชิ้น เริ่มต้น
    const previewProduct = await $axios.$get('patterns', {
      params: { request_for: 'sme', id: params.id }
    })

    // const patternsAll = await $axios.$get('/products', {
    const patternsAllData = await $axios.$get('/v2/products', {
      params: {
        page: 1,
        packaging_type_id: packagingTypeId,
        per_page: 99
      }
    })
    const patternsAll = patternsAllData.data
    let pouchInputData = null
    let pouchPatternData = null
    if (packagingTypeId === 6) {
      const resPouchInputData = await $axios.$get('pouch/get-input-data')
      pouchInputData = resPouchInputData.data
      const resPouchPattern = await $axios.$get('pouch/' + params.id)
      pouchPatternData = resPouchPattern.data
    }

    let patternData = {}
    patternData = Object.assign({}, patternsAll)
    if (
      typeof query.productId !== 'undefined' &&
      parseInt(query.productId) > 0
    ) {
      productData = await $axios.$get(
        'products-with-sample_product/' + query.productId + '/' + params.id
      )
      if (productData && 'sampleProduct' in productData.data) {
        sampleProductData = productData.data.sampleProduct
        if ('stdSize' in sampleProductData) {
          wldList = sampleProductData.stdSize
        }

        const listOfMapInputId = {}
        for (const idx in sampleProductData.mapping_input_id_list) {
          const id = parseInt(sampleProductData.mapping_input_id_list[idx])
          listOfMapInputId[id] = 1
        }
        if (sampleProductData && sampleProductData.mapping_input_id_list) {
          for (const idx in patternData) {
            const mapIdChk = patternData[idx].id
            if (!(mapIdChk in listOfMapInputId)) {
              delete patternData[idx]
            } else {
              patternData[idx].relateProductId =
                sampleProductData.relateProduct[mapIdChk]
            }
          }
        }
      }
    }

    const papers = result.data.papers
    const sampleProducts = result.data.sampleProducts
    const relatedProducts = result.data.relatedProducts
    let cardPaperActive = 0
    let cardPaperActiveName = ''
    if (papers.length > 0) {
      cardPaperActive = papers[0].id
      cardPaperActiveName = papers[0].name_th
    }

    let paperColor = ''
    if ([34, 54].includes(cardPaperActive)) {
      paperColor = 'white'
    } else {
      paperColor = 'brown'
    }

    result.data.patterns.forEach((pattern) => {
      pattern.special_technic_id = []
      pattern.color = 1
      if (packagingTypeId === 1) {
        pattern.enamel_id = 17
      } else if (packagingTypeId === 3) {
        pattern.enamel_id = 39
      } else {
        pattern.enamel_id = null
      }
    })

    // Set Min Date for Datepicker
    let setMindate = 0
    if (packagingTypeId === 1 || packagingTypeId === 5) {
      setMindate = 10
    } else if (packagingTypeId === 2) {
      setMindate = 3
    } else if (packagingTypeId === 3) {
      setMindate = 7
    } else {
    }

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setDate(minDate.getDate() + setMindate)
    const formmatMindate = `${minDate.getFullYear()}-${(minDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${minDate
      .getDate()
      .toString()
      .padStart(2, '0')}`

    const mappingInputs = result.data.mappingInput
    // const inputID = mappingInputs.sub_id

    const enamels = input.data[0].enamels
    const sampleProductImages = []
    let designPrice = 2500
    if (input.data.length > 0 && 'designPrice' in input.data[0] && input.data[0].designPrice) {
      designPrice = input.data[0].designPrice
    }

    for (const index in sampleProducts) {
      let paperTypeId = 0
      let colorPrint = 1
      let patternColorAmt = 1
      if (
        sampleProducts[index].sme_json &&
        sampleProducts[index].sme_json.patterns.length > 0
      ) {
        if ('paper_type_id' in sampleProducts[index].sme_json) {
          paperTypeId = parseInt(sampleProducts[index].sme_json.paper_type_id)
        }
        if ('paper_type_id' in sampleProducts[index].sme_json.patterns[0]) {
          paperTypeId = parseInt(
            sampleProducts[index].sme_json.patterns[0].paper_type_id
          )
        }
        if ('color' in sampleProducts[index].sme_json.patterns[0]) {
          colorPrint = parseInt(
            sampleProducts[index].sme_json.patterns[0].color
          )
        }
      }
      if (
        result.data.patterns &&
        result.data.patterns.length > 0 &&
        'color' in result.data.patterns[0]
      ) {
        patternColorAmt = result.data.patterns[0].color
      }
      if (cardPaperActive === paperTypeId && colorPrint === patternColorAmt) {
        if (sampleProducts[index].img.length > 2) {
          sampleProducts[index].img = sampleProducts[index].img.slice(0, 2)
        }
        sampleProductImages.push(sampleProducts[index])
      }
    }
    const imagesets = await $axios.$get(
      '/mapping_inputs/product_imagesets/' + mappingInputs.id
    )

    let userDesign = null
    if (query.hasDesign && query.uuid) {
      const res = await $axios.$get(`designs/get-design/${query.uuid}`)
      userDesign = res.data
    }
    const id = params.id
    return {
      id,
      pouchPatternData,
      pouchInputData,
      userDesign,
      mapping_inputs: mappingInputs,
      patterns: result.data.patterns,
      resultData: result.data,
      dimension_img: result.data.dimension_img,
      sampleProductImages,
      imagesets,
      paperColor,
      papers,
      sampleProducts,
      relatedProducts,
      enamels,
      special_technics: input.data[0].special_technics,
      cardPaperActive,
      cardPaperActiveName,
      design: false,
      designPrice,
      isBriefDesign: '',
      minDate: formmatMindate,
      previewProduct: previewProduct.data,
      // patternsAll: Object.values(patternData),
      patternsAll,
      patternSelected: parseInt(params.id),
      packagingTypeId,
      wldList,
      seo: $replaceSEO(
        seo.data.data,
        mappingInputs.name_th,
        params.id,
        null,
        null,
        null
      ),
      mappingInputsSubId: mappingInputs.sub_id,
      params,
      productData: productData.data,
      query,
      sampleProductData,
      promoCode: promoCode.data,
      promoCodePopup,
      promoCodeData
    }
  },
  data () {
    return {
      linkTo: {
        80: 464,
        76: 736
      },
      selected_key: null,
      previewList: [],
      userDesign: null,
      showModelDesigner: false,
      loadCombinationFirst: 0,
      patternIndex: [],
      mainPatternIndexActive: [],
      mainPatternActive: {
        paper_id: null,
        color: null,
        enamel_id: null
      },
      waypointReleteLoaded: false,
      loadingLastDesign: false,
      uuid: '',
      hasDesign: false,
      designData: {},
      provideTemplate: true,
      discountCodeTemplate: false,
      redeemPointTemplate: false,
      specialTechniqueTagHoldId: 0,
      default_img: '',
      enamelImgCorrugate: {},
      iframeHeight: '800px',
      useDesignTool: false,
      standard_size: '0',
      drawingData: '',
      drawingPNG: '',
      defaultUrlPKGdesigner: 'https://www.locopack.co/designer/',
      couponActive: false,
      promoCode: [],
      promoCodePopup: null,
      promoCodeData: [],
      promoCodeSelect: null,
      promoCodeTxt: '',
      showEdit: false,
      lineAdd: false,
      orderKeyRef: '',
      orderId: null,
      mapping_inputs: null,
      patterns: [],
      sampleProducts: {},
      relatedProducts: {},
      papers: {},
      currentFactory: {},
      enamels: {},
      special_technics: {},
      w: null,
      l: null,
      h: null,
      r: 0,
      dim: 0,
      w_for_calculate: null,
      l_for_calculate: null,
      h_for_calculate: null,
      diameter: '',
      zipCode: '',
      amount: 100,
      amount2: '',
      amount3: '',
      deliveryDate: '',
      imageUpload: [],
      patternTypeSelected: '',
      micronSelected: null,
      microns: [],
      spoutId: null,
      s: null,
      b: null,
      specialTechniqueTag: [
        { text: '3 มม.', value: 61 },
        { text: '6 มม.', value: 62 }
      ],
      patternTypeList: [
        { text: 'เลือกรูปแบบกล่อง', value: '' },
        { text: 'กระดาษแข็ง ฝาเปิดบน', value: 1 }
      ],
      wldSelected: '',
      paperSizeSelected: 'custom',
      paperSizeList: [
        { text: 'ขนาดมาตรฐาน', value: 'normal' },
        { text: 'กำหนดเอง', value: 'custom' }
      ],
      designList: [
        { text: 'ต้องการให้ช่วยออกแบบ', value: true },
        { text: 'มีแบบแล้ว', value: false }
      ],
      paperGramSelected: 350,
      paperGramList: [
        [
          { text: '300 แกรม', value: 300 },
          { text: '350 แกรม', value: 350 }
        ],
        [
          { text: '300 แกรม', value: 300 },
          { text: '385 แกรม', value: 385 },
          { text: '420 แกรม', value: 420 }
        ],
        [
          { text: '270 แกรม', value: 270 },
          { text: '300 แกรม', value: 300 },
          { text: '350 แกรม', value: 350 }
        ]
      ],
      glazedSelected: '',
      glazedLists: [
        { text: 'ไม่เคลือบ', value: '' },
        { text: 'เคลือบพลาสติกมัน', value: 1 }
      ],
      glazedSelected2: '',
      glazedLists2: [
        { text: 'ไม่เคลือบ', value: '' },
        { text: 'เคลือบพลาสติกมัน', value: 1 }
      ],
      specialList: [
        { text: 'Spot UV (1 จุดขนาดไม่เกิน 5 x 5 ซม.)', check: false },
        { text: 'ปั้มจม (1 จุดขนาดไม่เกิน 5 x 5 ซม.)', check: false },
        { text: 'ติดลิ้นกาวพิเศษ', check: false },
        { text: 'ปั๊มนูน (1 จุดขนาดไม่เกิน 3 x 3 ซม.)', check: false },
        { text: 'ติดลิ้นกาวข้างกล่อง', check: false },
        {
          text: 'ปั๊มเงิน / ปั๊มทอง (1 จุดขนาดไม่เกิน 2.5 x 2.5 ซม.)',
          check: false
        },
        { text: 'เจาะและติดหน้าต่าง', check: false }
      ],
      specialList2: [
        { text: 'Spot UV (1 จุดขนาดไม่เกิน 5 x 5 ซม.)', check: false },
        { text: 'ปั้มจม (1 จุดขนาดไม่เกิน 5 x 5 ซม.)', check: false },
        { text: 'ติดลิ้นกาวพิเศษ', check: false },
        { text: 'ปั๊มนูน (1 จุดขนาดไม่เกิน 3 x 3 ซม.)', check: false },
        { text: 'ติดลิ้นกาวข้างกล่อง', check: false },
        {
          text: 'ปั๊มเงิน / ปั๊มทอง (1 จุดขนาดไม่เกิน 2.5 x 2.5 ซม.)',
          check: false
        },
        { text: 'เจาะและติดหน้าต่าง', check: false }
      ],
      paperColor: '',
      cardPaperActive: null,
      cardPaperActiveName: null,
      imageOrder: require('~/static/images/select-products/order1-1.jpg'),
      image1: require('~/static/images/select-products/image1-2.jpg'),
      image3_1: require('~/static/images/select-products/image3-1.jpg'),
      image4_1: require('~/static/images/select-products/image4-1.jpg'),
      colorPrints: [
        {
          text: 'ไม่พิมพ์สี',
          img: require('~/static/images/select-products/folding_nocolor.png'),
          value: 0
        },
        {
          text: 'พิมพ์ 4 สี เสมือนจริง',
          img: require('~/static/images/select-products/folding_color.png'),
          value: 1
        }
      ],
      colorPrintsLabel: [
        {
          text: 'ไม่พิมพ์สี',
          img: {
            24: require('~/static/images/select-products/label_nocolor24.png'),
            25: require('~/static/images/select-products/label_nocolor25.png'),
            26: require('~/static/images/select-products/label_nocolor26.png')
          },
          value: 0
        },
        {
          text: 'พิมพ์ 4 สี เสมือนจริง',
          img: {
            24: require('~/static/images/select-products/label_color24.png'),
            25: require('~/static/images/select-products/label_color25.png'),
            26: require('~/static/images/select-products/label_color26.png')
          },
          value: 1
        }
      ],
      colorPrintsCorrugate: [
        {
          text: 'ไม่พิมพ์สี',
          img: require('~/static/images/select-products/corrugate_nocolor.png'),
          value: 0
        },
        {
          text: 'พิมพ์ 1 สี',
          img: require('~/static/images/select-products/corrugate_color.png'),
          value: 1
        },
        {
          text: 'พิมพ์ 4 สี',
          img: require('~/static/images/select-products/corrugate_color.png'),
          value: 4
        }
      ],
      colorText: {
        1: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        2: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        3: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 1 สี',
          4: 'พิมพ์ 4 สี เสมือนจริง'
        },
        5: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        6: {
          1: 'พิมพ์ 4 สี เสมือนจริง'
        }
      },
      forShare: '',
      shareUrl: '',
      factorySelected: null,
      totalResult: null,
      factoryList: [],
      factoryListOther: [],
      expandPrice: [false, false, false],
      amountOther: [0, 0, 0],
      factoryPic: {
        3: [
          { path: 'factory_pic/3A/Folding/3A_F1.png' },
          { path: 'factory_pic/3A/Folding/3A_F2.png' },
          { path: 'factory_pic/3A/Folding/3A_F3.png' },
          { path: 'factory_pic/3A/Folding/3A_F4.png' },
          { path: 'factory_pic/3A/Folding/3A_F5.png' },
          { path: 'factory_pic/3A/Folding/3A_F6.png' },
          { path: 'factory_pic/3A/Folding/3A_F7.png' },
          { path: 'factory_pic/3A/Folding/3A_F8.png' },
          { path: 'factory_pic/3A/Folding/3A_F9.png' },
          { path: 'factory_pic/3A/Folding/3A_F10.png' }
        ],
        4: [
          { path: 'factory_pic/4A/Corr/4a_C1.png' },
          { path: 'factory_pic/4A/Corr/4A_C2.png' },
          { path: 'factory_pic/4A/Corr/4A_C3.png' },
          { path: 'factory_pic/4A/Corr/4A_c4.png' },
          { path: 'factory_pic/4A/Corr/4A_C5.png' },
          { path: 'factory_pic/4A/Corr/4A_C6.png' }
        ],
        6: [
          { path: 'factory_pic/6A/Folding/6A_F1.png' },
          { path: 'factory_pic/6A/Folding/6A_F2.png' },
          { path: 'factory_pic/6A/Folding/6A_F3.png' },
          { path: 'factory_pic/6A/Folding/6A_F4.png' },
          { path: 'factory_pic/6A/Folding/6A_F5.png' },
          { path: 'factory_pic/6A/Folding/6A_F6.png' }
        ],
        9: [
          { path: 'factory_pic/9A/Corr/9A_C.png' },
          { path: 'factory_pic/9A/Folding/9A_F1.png' },
          { path: 'factory_pic/9A/Folding/9A_F2.png' },
          { path: 'factory_pic/9A/Folding/9A_F3.png' },
          { path: 'factory_pic/9A/Folding/9A_F4.png' },
          { path: 'factory_pic/9A/Folding/9A_F5.png' },
          { path: 'factory_pic/9A/Folding/9A_F6.png' },
          { path: 'factory_pic/9A/Folding/9A_F7.png' },
          { path: 'factory_pic/9A/Folding/9A_F8.png' },
          { path: 'factory_pic/9A/Folding/9A_F9.png' },
          { path: 'factory_pic/9A/Sticker/9A_S1.png' },
          { path: 'factory_pic/9A/Sticker/9A_S2.png' },
          { path: 'factory_pic/9A/Sticker/9A_S3.png' },
          { path: 'factory_pic/9A/Sticker/9A_S4.png' },
          { path: 'factory_pic/9A/Sticker/9A_S5.png' }
        ],
        10: [
          { path: 'factory_pic/10A/Folding/10A_1.jpg' },
          { path: 'factory_pic/10A/Folding/10A_2.jpg' },
          { path: 'factory_pic/10A/Folding/10A_3.jpg' },
          { path: 'factory_pic/10A/Folding/10A_4.jpg' },
          { path: 'factory_pic/10A/Folding/10A_5.jpg' },
          { path: 'factory_pic/10A/Folding/10A_6.jpg' },
          { path: 'factory_pic/10A/Folding/10A_7.jpg' },
          { path: 'factory_pic/10A/Folding/10A_8.jpg' },
          { path: 'factory_pic/10A/Folding/10A_9.jpg' },
          { path: 'factory_pic/10A/Folding/10A_10.jpg' },
          { path: 'factory_pic/10A/Folding/10A_11.jpg' },
          { path: 'factory_pic/10A/Folding/10A_12.jpg' }
        ],
        11: [
          { path: 'factory_pic/11A/Folding/11A_F1.png' },
          { path: 'factory_pic/11A/Folding/11A_F2.png' },
          { path: 'factory_pic/11A/Folding/11A_F3.png' },
          { path: 'factory_pic/11A/Folding/11A_F4.png' },
          { path: 'factory_pic/11A/Folding/11A_F5.png' },
          { path: 'factory_pic/11A/Folding/11A_F6.png' },
          { path: 'factory_pic/11A/Folding/11A_F7.png' },
          { path: 'factory_pic/11A/Folding/11A_F8.png' },
          { path: 'factory_pic/11A/Folding/11A_F9.png' },
          { path: 'factory_pic/11A/Folding/11A_F10.png' }
        ],
        16: [
          { path: 'factory_pic/16/2.2.jpg' },
          { path: 'factory_pic/16/2.4.jpg' },
          { path: 'factory_pic/16/2.5.jpg' },
          { path: 'factory_pic/16/15.1.jpg' },
          { path: 'factory_pic/16/15.4.jpg' },
          { path: 'factory_pic/16/15.5.jpg' },
          { path: 'factory_pic/16/17.1.jpg' },
          { path: 'factory_pic/16/17.4.jpg' },
          { path: 'factory_pic/16/17.5.jpg' }
        ]
      },
      guidInfo: {
        BOX_TYPE: {
          attributeName: 'รูปแบบกล่อง',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'ฝาเปิดบน',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ฝาเปิดบนหูแขวน',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ฝาเปิดบนป๊อปอัพ',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ฝาเปิดบนหูแขวนป๊อปอัพ',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ทรงบ้าน',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ฝาครอบนอก',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'ฝาสองชั้น',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'สแน็คบ๊อกเสียบข้าง',
              note: '',
              description: '',
              videoLink: null
            },
            {
              name: 'สแน็คบ๊อกข้างเรียบ',
              note: '',
              description: '',
              videoLink: null
            }
          ]
        },
        BOX_SIZE: {
          attributeName: 'ขนาดกล่อง',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'กำหนดเอง ',
              note: 'กว้างxยาวxสูง(ซม)',
              description: '',
              videoLink: null
            },
            {
              name: 'มาตรฐาน',
              note: 'กว้างxยาวxสูง(ซม)',
              description: '',
              videoLink: null
            }
          ]
        },
        PAPER_TYPE: {
          attributeName: 'ประเภทกระดาษ',
          displayMode: 'OPTIONS',
          attributeList: [
            {
              name: 'กระดาษอาร์ตการ์ด ',
              note: '',
              description:
                'กระดาษพรีเมียมสีขาว พิมพ์สีสวยคมชัด เนื้อแน่น ผิวเรียบ ทำให้กล่องแข็งแรง คงรูป ไม่ยวบ ช่วยให้ภาพลักษณ์ของสินค้าดูพรีเมียม มักเป็นกระดาษทำปกพ็อกเก็ตปุ๊ก กล่องเครื่องสำอาง',
              videoLink: 'https://files.locopack.co/videos/Art300.mp4'
            },
            {
              name: 'กระดาษกล่องแป้งหน้าขาวหลังขาว ',
              note: '',
              description:
                'กระดาษกล่องแป้งหน้าขาวหลังขาว ผิวด้านหน้าขาวอมฟ้าเรียบ ผิวด้านหลังขาวอมเทาไม่เรียบ เหมาะกับการพิมพ์ด้านเดียว เป็นกระดาษสำหรับทำกล่องบรรจุภัณฑ์',
              videoLink: 'https://files.locopack.co/videos/DP300.mp4'
            },
            {
              name: 'กระดาษกล่องแป้งหน้าขาวหลังเทาขาว ',
              note: '',
              description:
                'กระดาษกล่องแป้งหน้าขาวหลังขาว ผิวด้านหน้าขาวอมฟ้าเรียบ ผิวด้านหลังเทาน้ำตาล เหมาะกับการพิมพ์ด้านเดียว เป็นกระดาษสำหรับทำกล่องบรรจุภัณฑ์ที่ไม่เน้นการโชว์กล่องด้านใน',
              videoLink: 'https://files.locopack.co/videos/DP350.mp4'
            },
            {
              name: 'กระดาษคราฟท์ ',
              note: '',
              description:
                'กระดาษคราฟท์น้ำตาลธรรมชาติ ผิวหยาบ เมื่อพิมพ์สีแล้วสีจะซึมลงในกระดาษทำให้สีดูหม่น เป็นสี earth tone เหมาะกับสินค้าที่ดูออแกนิค, รีไซเคิล, เป็นมิตรกับสิ่งแวดล้อม',
              videoLink: 'https://files.locopack.co/videos/Kraft300.mp4'
            },
            {
              name: 'กระดาษอาร์ตการ์ด food-grade ',
              note: '',
              description:
                'กระดาษพรีเมียมสีขาว พิมพ์สีสวยคมชัด เนื้อแน่น ผิวเรียบ ทำให้กล่องแข็งแรง คงรูป ไม่ยวบ ช่วยให้ภาพลักษณ์ของสินค้าดูพรีเมียม ที่สำคัญเป็นกระดาษ food grade ทำให้เพิ่มความปลอดภัยในการสัมผัสกับอาหารมากยิ่งขึ้น',
              videoLink: 'https://files.locopack.co/videos/Art300.mp4'
            }
          ]
        },
        PAPER_TYPE_CORRUGATE: {
          attributeName: 'ประเภทกระดาษ',
          displayMode: 'OPTIONS',
          attributeList: [
            {
              name: 'กระดาษสีนำตาล 3 ชั้น ลอนB',
              note: 'ฝาชน,ฝาเสียบคู่, ฝาเสียบเดียว',
              description:
                'กระดาษลูกฟูก 3 ชั้น ลอน B ด้านนอกเป็นสีนำตาล เหมาะสำหรับกล่องไปรษณีย์, กล่องสินค้าที่มีขนาดใหญ่ แข็งแรง',
              videoLink: 'https://files.locopack.co/videos/BrownCorrugate3.mp4'
            },
            {
              name: 'กระดาษสีขาว 3 ชั้น ลอนB',
              note: 'ฝาชน,ฝาเสียบคู่, ฝาเสียบเดียว',
              description:
                'กระดาษลูกฟูก 3 ชั้น ลอน B ด้านนอกเป็นสีขาว สวยงาม เหมาะสำหรับกล่องติดแบรนด์ กล่องไปรษณีย์, กล่องสินค้าที่มีขนาดใหญ่ แข็งแรง',
              videoLink:
                'https://files.locopack.co/videos/WhiteBrownCorrugate3.mp4'
            },
            {
              name: 'กระดาษสีนำตาล 5 ชั้น ลอนBC',
              note: 'ฝาชน',
              description:
                'กระดาษลูกฟูก 5 ชั้น ลอน BC ด้านนอกเป็นสีนำตาล เหมาะสำหรับกล่องสินค้าที่มีขนาดใหญ่ แข็งแรงมาก',
              videoLink: 'https://files.locopack.co/videos/BrownCorrugate5.mp4'
            },
            {
              name: 'กระดาษสีนำตาล 3 ชั้น ลอนE',
              note: 'ฝาเสียบคู่',
              description: 'กระดาษลูกฟูก 3 ชั้น ลอน E ด้านนอกเป็นสีนำตาล',
              videoLink: ''
            },
            {
              name: 'กระดาษสีขาว 3 ชั้น ลอนE',
              note: 'ฝาเสียบคู่',
              description: 'กระดาษลูกฟูก 3 ชั้น ลอน E ด้านนอกเป็นสีขาว ',
              videoLink: 'https://www.youtube.com/embed/3XQ6R4PeC_c'
            }
          ]
        },
        MATERIAL_TYPE: {
          attributeName: 'ประเภทวัสดุ',
          displayMode: 'OPTIONS',
          attributeList: [
            {
              name: 'ซองพลาสติกขาว',
              note: '',
              description: 'ใช้วัสดุ LLDPE สีขาว เนื้อพลาสติกมีความนิ่ม เหมาะสำหรับบรรจุอาหารที่ไม่ต้องการการป้องกันมากนัก',
              videoLink: null
            },
            {
              name: 'ซองพลาสติกใส',
              note: '',
              description: 'ใช้วัสดุ PET/LLDPE  เนื้อพลาสติกมีความใสสามารถมองเห็นสินค้าด้านใน ทนต่อการฉีกขาดและแรงกระแทก ป้องกันการซึมผ่านของก๊าซได้ดี เหมาะกับผลิตภัณฑ์ที่ไม่ใช่อาหาร หรือผลิตภัณฑ์อาหารที่ต้องรักษาความกรอบก็ได้ เช่น ขนมอบกรอบ ผลิตแปรรูปจากผลไม้',
              videoLink: null
            },
            {
              name: 'ซองฟอยล์เมทัลไลซ์',
              note: '',
              description: 'ใช้วัสดุ PET/MPET/LLDPE  เนื้อพลาสติกมีความเงา แวววาว ป้องกันการซึมผ่านของก๊าซ และแสงที่จะส่งผลเสียต่อตัวผลิตภัณฑ์ได้ดี ช่วยยืดอายุสินค้าได้ดีกว่าซองใสทั่วไป เหมาะกับผลิตภัณฑ์อาหาร เช่นขนมทอดกรอบ ผงปรุงรส',
              videoLink: null
            },
            {
              name: 'ซองฟอยล์อลูมิเนียม',
              note: '',
              description: 'ใช้วัสดุ PET/ALU/LLDPE  เนื้อพลาสติกมีความเงา แวววาว ป้องกันการซึมผ่านของก๊าซ แสง และไอน้ำได้อย่างดีเยี่ยม สามารถเก็บรักษาสินค้าที่อยู่ภายในได้ยาวนานกว่าฟิล์มชนิดอื่น เหมาะกับผลิตภัณฑ์ที่ต้องการการป้องกันอย่างสูง เช่นผลไม้อบแห้ง เมล็ดธัญพืช เม็ดมะม่วงหิมพานต์ ถั่วอบต่างๆ สามารถติดวาล์ว Degassing สำหรับบรรจุเมล็ดกาแฟ',
              videoLink: null
            },
            {
              name: 'ซองไนลอนใส',
              note: '',
              description: 'ใช้วัสดุ PA/LLDPE เนื้อพลาสติกมีความใสสามารถมองเห็นสินค้าด้านใน มีความต้านทานต่อการรั่วซึม ทนได้ทั้งอุณภูมิร้อน และเย็น มีความเหนียวเป็นพิเศษ นิยมทำเป็นบรรจุภัณฑ์อาหารที่ต้องแช่เย็น ถุงบรรจุข้าวสารหรือบรรจุภัณฑ์ที่ต้องการการซีลแบบสูญญากาศ',
              videoLink: null
            },
            {
              name: 'ซองกระดาษคราฟท์',
              note: '',
              description: 'ใช้วัสดุ กระดาษคราฟท์สีน้ำตาล/LLDPE เนื้อพลาสติกมีสีน้ำตาล เป็นการเพิ่มความน่าสนใจให้กับผลิตภัณฑ์ที่เน้นความคลาสสิก ผลิตภัณฑ์ออร์แกนิค หรือผลิตภัณฑ์รักษ์โลก เหมาะกับผลิตภัณฑ์ของแห้งที่อายุการขายไม่นานมากนัก เช่นขนมคุกกี้ ขนมคอนเฟลก ข้าวเกรียบ ขนมปัง',
              videoLink: null
            },
            {
              name: 'ซองกระดาษคราฟท์ติดฟอยล์อลูมิเนียม',
              note: '',
              description: 'ใช้วัสดุ กระดาษคราฟท์สีน้ำตาล/ALU/LLDPE เนื้อพลาสติกด้านนอกมีสีน้ำตาล ด้านในมีสีเงิน เป็นการเพิ่มความน่าสนใจให้กับผลิตภัณฑ์ที่เน้นความคลาสสิก ผลิตภัณฑ์ออร์แกนิค หรือผลิตภัณฑ์รักษ์โลก สามารถป้องกันการซึมผ่านของก๊าซ แสง และไอน้ำได้ เหมาะกับผลิตภัณฑ์ทั่วไป เช่น ขนมอบกรอบ ขนมคุกกี้ ขนมคอนเฟลก สามารถติดวาล์ว Degassing สำหรับบรรจุเมล็ดกาแฟ',
              videoLink: null
            }
          ]
        },
        MATERIAL_MICRONS: {
          attributeName: 'ความหนา',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: '40',
              note: '',
              description: 'ซองชีล 3 ด้าน ซองชีลกลาง ซองชีลกลางขยายข้าง ซองชีล 4 ด้าน ซองตั้ง',
              videoLink: null
            },
            {
              name: '60',
              note: '',
              description: 'ซองชีล 3 ด้าน ซองชีลกลาง ซองชีลกลางขยายข้าง ซองชีล 4 ด้าน ซองตั้ง',
              videoLink: null
            },
            {
              name: '80',
              note: '',
              description: 'ซองชีล 3 ด้าน ซองชีลกลาง ซองชีลกลางขยายข้าง ซองชีล 4 ด้าน ซองตั้ง',
              videoLink: null
            },
            {
              name: '100',
              note: '',
              description: 'ซองชีล 3 ด้าน ซองชีลกลาง ซองชีลกลางขยายข้าง ซองชีล 4 ด้าน ซองตั้ง',
              videoLink: null
            },
            {
              name: '120',
              note: '',
              description: 'ซองชีล 3 ด้าน ซองชีลกลาง ซองชีลกลางขยายข้าง ซองชีล 4 ด้าน ซองตั้ง',
              videoLink: null
            }
          ]
        },
        PAPER_THICK: {
          attributeName: 'ความหนากระดาษ',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: '270 แกรม',
              note:
                'กระดาษอาร์ตการ์ด food-grade',
              description:
                '270 แกรม เหมาะสำหรับบรรจถัณฑ์ที่ขนาดเล็ก น้ำหนักเบา อายุการใช้งานบรรจุภัณฑ์ไม่นานนัก เช่นบรรจุภัณฑ์ที่กินแล้วทิ้ง',
              videoLink: null
            },
            {
              name: '300 แกรม',
              note:
                'กระดาษอาร์ตการ์ด, กระดาษกล่องแป้งหลังขาว,กระดาษกล่องแป้งหลังเทา หลังเทา',
              description:
                '300 แกรม เหมาะสำหรับกบรรจุภัณฑ์ที่มีขนาดเล็ก และน้ำหนักไม่หนักเกิน 200 กรัม',
              videoLink: null
            },
            {
              name: '350 แกรม',
              note:
                'กระดาษอาร์ตการ์ด, กระดาษกล่องแป้งหลังขาว,กระดาษกล่องแป้งหลังเทา ',
              description:
                '350 แกรม เหมาะสำหรับทำบรรจุภัณฑ์กล่องเครื่องสำอาง อาหาร เครื่องประดับ',
              videoLink: null
            },
            {
              name: '385 แกรม',
              note: 'กระดาษคราฟ',
              description: '385 แกรม มีความแข็งแรงเนื้อแน่น',
              videoLink: null
            }
          ]
        },
        COLOR_NO: {
          attributeName: 'จำนวนสีพิมพ์',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'ไม่พิมพ์',
              note: '',
              description: 'ไม่มีการพิมพ์ลงบนชิ้นงาน',
              videoLink: null
            },
            {
              name: 'พิมพ์ 4 สี เสมือนจริง',
              note: '',
              description:
                'การพิมพ์แม่สี ฟ้าอมเขียว แดงอมม่วง เหลือง และดำลงไปบนกระดาษทำให้เกิดภาพเสมือนจริงขึ้นมา ตัวอย่างเช่น ปกนิตยสาร',
              videoLink: null
            }
          ]
        },
        ENAMEL_TYPE: {
          attributeName: 'การเคลือบ',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'เคลือบพลาสติกมัน',
              note: '',
              description: 'มีความมันค่อนข้างสูง ผิวกันน้ำได้ระดับหนึ่ง',
              videoLink:
                'https://files.locopack.co/videos/GlossyPlasticLaminate.mp4'
            },
            {
              name: 'เคลือบพลาสติกด้าน',
              note: '',
              description: 'มีความด้านค่อนข้างสูง ผิวกันน้ำได้ระดับหนึ่ง',
              videoLink:
                'https://files.locopack.co/videos/MattPlasticLaminate.mp4'
            },
            {
              name: 'เคลือบมัน',
              note: '',
              description: 'มีความมันปานกลาง ผิวไม่กันน้ำ',
              videoLink: null
            },
            {
              name: 'เคลือบด้าน',
              note: '',
              description: 'มีความด้านปานกลาง ผิวไม่กันน้ำ',
              videoLink: null
            }
          ]
        },
        SPECIAL_TECH: {
          attributeName: 'เทคนิคพิเศษ',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'Spot UV',
              note: '',
              description: 'เพิ่มคววามเงาเฉพาะจุด เหมาะกับการเคลือบด้าน ',
              videoLink: 'https://files.locopack.co/videos/SpotUV.mp4'
            },
            {
              name: 'ปั๊มนูน (1 จุดขนาดไม่เกิน 5 x 5 ซม)',
              note: '',
              description: 'ปั๊มพื้นผิวให้นูนขึ้นมาจากพื้นผิวปกติ ',
              videoLink: 'https://files.locopack.co/videos/Emboss_นูน.mp4'
            },
            {
              name: 'ปั๊มจม (1 จุดขนาดไม่เกิน 5 x 5 ซม)',
              note: '',
              description: 'ปั๊มพื้นผิวให้จมลงจากพื้นผิวปกติ ',
              videoLink: null
            },
            {
              name: 'ปั๊มฟอยล์ (1 จุดขนาดไม่เกิน 5 x 5 ซม)',
              note: '',
              description:
                'ปั๊มฟอยล์สีเงิน หรือสีทอง ลงบนนผิวกล่อง เพื่อเพิ่มความโดดเด่น',
              videoLink: 'https://files.locopack.co/videos/FoilStamp.mp4'
            }
          ]
        },
        SPECIAL_TECH_POUCH: {
          attributeName: 'Option เสริม',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'ติดซิบ',
              note: '',
              description: 'ติดซิปล็อคเพิ่มความสะดวกในการเปิด และปิด เมื่อรับประทานไม่หมด',
              videoLink: null
            },
            {
              name: 'ติด Degassing กาแฟ',
              note: '',
              description: 'ติดวาล์วเพื่อช่วยในการคายแก๊สสำหรับซองบรรจุเมล็ดกาแฟ',
              videoLink: null
            }
          ]
        },
        SPOUT_POUCH: {
          attributeName: 'Spout (ตัวดูด)',
          attributeType: 'OPTIONS',
          attributeList: [
            {
              name: 'Spout Size S',
              note: '',
              description: 'ติดจุกเพื่อความสะดวกในการใช้งาน เวลาเทผลิตภัณฑ์ของเหลวออกจากซอง',
              videoLink: null
            },
            {
              name: 'Spout Size M',
              note: '',
              description: 'ติดจุกเพื่อความสะดวกในการใช้งาน เวลาเทผลิตภัณฑ์ของเหลวออกจากซอง',
              videoLink: null
            }
          ]
        },
        DUE_DATE: {
          attributeName: 'วันที่ต้องการ',
          attributeType: 'SINGLE',
          attributeDescription:
            'วันที่สามารถเลือกได้ขึ้นเฉพาะวันที่เราสามารถผลิตได้'
        },
        ZIPCODE: {
          attributeName: 'รหัสไปรษณีที่จัดส่ง',
          attributeType: 'SINGLE',
          attributeDescription: ''
        },
        QUANTITY: {
          attributeName: 'จำนวน (ชิ้น)',
          attributeType: 'SINGLE',
          attributeDescription: ''
        }
      },
      utmSource: '',
      reward: []
    }
  },

  computed: {
    ...mapState(['userData']),
    ...mapGetters(['isAuthenticated', 'semiDesignData']),
    colorPrintActive: {
      get () {
        let color = 0
        this.patterns.forEach((item) => {
          if (item.color === 1) {
            color = 1
          }
          if (item.color === 4) {
            color = 4
          }
        })
        return color
      },
      set (color) {
        if (typeof color !== 'undefined') {
          return color
        }
      }
    },
    zipcodeState () {
      const Zipcode = /^[0-9]{5}$/
      return !(
        this.zipCode === null ||
        this.zipCode.length === 0 ||
        Zipcode.test(this.zipCode) === false
      )
    },
    zipcodeInvalidFeedback () {
      const Zipcode = /^[0-9]{5}$/
      if (
        this.zipCode === null ||
        this.zipCode.length === 0 ||
        Zipcode.test(this.zipCode) === false
      ) {
        return 'กรุณากรอกรหัสไปรษณีย์ให้ครบถ้วน'
      }
      return 'กรุณากรอกรหัสไปรษณีย์'
    },
    amountState () {
      return this.amount >= 100 && this.amount <= 10000
    },
    amountInvalidFeedback () {
      if (this.amount < 100) {
        return 'จำนวนสั่งขั้นต่ำ 100 ชิ้น'
      } else if (this.amount >= 10000) {
        return 'จำนวนเกิน 10,000 ชิ้น กรุณาติดต่อเราที่ line @locopack'
      }
      return 'กรุณากรอกจำนวนที่ต้องการสั่ง'
    }
  },

  watch: {
    $route () {
      this.$nuxt.refresh()
    },
    query (val) {
      this.hideLoading()
    }
  },

  async mounted () {
    console.log('customizeeeeeeeeeeeeeeddddd')
    localStorage.setItem('discountCodeTemplate', false)
    localStorage.setItem('redeemPointTemplate', false)
    if (typeof this.query.standardSizeId === 'undefined') {
      const tmpParam = JSON.parse(localStorage.getItem('click-login-from-page-query'))
      if (tmpParam && 'standardSizeId' in tmpParam) {
        delete tmpParam.standardSizeId
        localStorage.setItem('click-login-from-page-query', JSON.stringify(tmpParam))
      }
    }
    if (typeof this.query.semiDesignEditMode === 'undefined') {
      const tmpParam = JSON.parse(localStorage.getItem('click-login-from-page-query'))
      if (tmpParam && 'semiDesignEditMode' in tmpParam) {
        delete tmpParam.semiDesignEditMode
        localStorage.setItem('click-login-from-page-query', JSON.stringify(tmpParam))
      }
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'product',
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: this.userData ? this.userData.uid : '',
        customerEmail: this.userData ? this.userData.email : ''
      }
    })
    if (!this.query.semiDesignEditMode) {
      this.setPaperVideo()
    }
    this.hideLoading()
    if (this.query.shareUrl) {
      const shareProduct = await this.$axios.$get(`share-products/${this.query.shareUrl}`)
      if (shareProduct.status === true) {
        if ('promotion_code' in shareProduct.data.params) {
          shareProduct.data.params.promotion_code = ''
        }
        localStorage.setItem('order' + shareProduct.data.params.mapping_inputs.id, JSON.stringify(shareProduct.data))
      }
    }

    this.setupEnamelImgCorrugate()
    if (this.mapping_inputs.id === 57 || this.mapping_inputs.id === 58) {
      this.specialTechniqueTagHoldId = 61
      this.patterns[0].special_technic_id = [this.specialTechniqueTagHoldId]
    }
    if (this.mapping_inputs.id === 60 || this.mapping_inputs.id === 67 || [96, 97, 98, 99].includes(this.mapping_inputs.id)) {
      this.paperSizeList = [{ text: 'ขนาดมาตรฐาน', value: 'normal' }]
    } else if (this.mapping_inputs.id === 66) {
      this.paperSizeList = [{ text: 'กำหนดเอง', value: 'custom' }]
    }
    window.objCurrentPage = this
    window.closeDesignNew = function () {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      return true
    }
    window.hideLoadingFromIframe = function () {
      window.objCurrentPage.hideLoading()
      return true
    }
    window.showLoadingFromIframe = function () {
      window.objCurrentPage.showLoading()
      return true
    }
    window.closeAndSaveDesignNew = function (uuid, saveOnly) {
      window.objCurrentPage.uuid = uuid
      const dataStore = {
        params: window.objCurrentPage.prepareData(false),
        factorySelected: window.objCurrentPage.factorySelected,
        fromModalDesigner: true
      }
      window.objCurrentPage.storeOrder2LocalStorage(dataStore)
      if (saveOnly === false) {
        window.objCurrentPage.$bvModal.hide('modalDesigner')
        window.objCurrentPage.$bvModal.show('modal-warning-order')
      }
      if (typeof window.objCurrentPage.$refs.myDesignModal.reloadDesigns !== 'undefined' &&
        window.objCurrentPage.$refs.myDesignModal.show) {
        window.objCurrentPage.$refs.myDesignModal.reloadDesigns()
      }
      window.objCurrentPage.getLastDesign()
      return true
    }
    window.closeAndSaveDesign = function (data) {
      window.objCurrentPage.$bvModal.hide('modalDesigner')
      window.objCurrentPage.setDrawingData(data)
      return true
    }
    window.clearSemiDesignEditMode = function () {
      if (window.objCurrentPage.query.semiDesignEditMode) {
        const query = Object.assign({}, window.objCurrentPage.$route.query)
        delete query.semiDesignEditMode
        window.objCurrentPage.$router.replace({ query })
        window.objCurrentPage.$cookies.remove('semiDesignEditMode')
        window.objCurrentPage.$cookies.remove('semiDesignData')
      }
    }
    window.savePngFromDesigner = function (data) {
      window.objCurrentPage.setDrawingPNG(data)
      return true
    }
    window.returnDrawingData = function () {
      return window.objCurrentPage.drawingData
    }
    this.orderKeyRef = 'order' + this.mapping_inputs.id
    if (this.query.productId) {
      this.orderKeyRef = this.orderKeyRef + '_' + this.query.productId
    }

    // this.utmSource = this.$cookies.get('locopack_utm_source')
    this.utmSource = this.getCookie({ cookieName: 'locopack_utm_source' })
    if (
      (this.mapping_inputs.packaging_type_id === 3 &&
        this.wldList.length > 0) ||
      (this.mapping_inputs.packaging_type_id === 5 &&
        this.mapping_inputs.id !== 59 &&
        this.mapping_inputs.id !== 66) ||
        [96, 97, 98, 99].includes(this.mapping_inputs.id)
    ) {
      this.paperSizeSelected = 'normal'
      this.useDesignTool = false // change default later
    } else {
      this.paperSizeSelected = 'custom'
      this.useDesignTool = false
    }
    if (
      localStorage.getItem('myZipcode') !== null &&
      localStorage.getItem('myZipcode') !== ''
    ) {
      this.zipCode = localStorage.getItem('myZipcode')
      this.$refs.tooltip.$emit('close')
      this.$refs.tooltip.$emit('disable')
    } else {
      console.log('check local dont have zipcode')
      this.$refs.tooltip.$emit('open')
      this.$refs.tooltip.$emit('enable')
      // this.$refs.modalMyZipcode.show()
    }

    if (this.isAuthenticated) {
      const promoCodeStorage = JSON.parse(localStorage.getItem('promoCode')) || []
      if (promoCodeStorage) {
        for (const item of promoCodeStorage) {
          try {
            await this.$axios.$post('save-promo-code', { code: item.code })
          } catch (err) {
            this.setAlert({
              show: true,
              message: err.message,
              header: 'แจ้งเตือน'
            })
            this.hideLoading()
          }
        }
        localStorage.removeItem('promoCode')
      }
      try {
        this.$axios.$get('get-user-promo-codes').then((res) => {
          const codeArray = []
          for (const item of res) {
            codeArray.push({ code: item.code, name: item.name })
          }
          this.promoCode = codeArray
          if (this.promoCode && this.promoCode.length > 0) {
            this.promoCodeSelect = this.promoCode[0]
          }
          this.showpopup()
        })
      } catch (err) {
        this.setAlert({
          show: true,
          message: err.message,
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
      }
    } else {
      const codes = []
      for (const i in this.promoCodeData) {
        codes.push(this.promoCodeData[i].code)
      }
      const localStorageCode =
        JSON.parse(localStorage.getItem('promoCode')) || []
      const promocodeActive = []
      for (const promoCode of localStorageCode) {
        if (codes.includes(promoCode.code)) {
          promocodeActive.push(promoCode)
        }
      }
      this.promoCode = promocodeActive
      localStorage.setItem('promoCode', JSON.stringify(promocodeActive))
      if (this.promoCode && this.promoCode.length > 0) {
        this.promoCodeSelect = this.promoCode[0]
      }
      this.showpopup()
    }

    if (this.isAuthenticated) {
      try {
        this.$axios.$get('rewards/packaging_type_id/' + this.packagingTypeId).then((res) => {
          this.reward = res
        })
      } catch (err) {
        this.setAlert({
          show: true,
          message: err.message,
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
      }
    }

    if (this.mapping_inputs.packaging_type_id === 6) {
      this.amount = 5000
      this.w = this.pouchPatternData.min_w
      this.l = this.pouchPatternData.min_l
      if (this.pouchPatternData.required_s === 1) {
        this.s = this.pouchPatternData.min_s
      }
      if (this.pouchPatternData.required_b === 1) {
        this.b = this.pouchPatternData.min_b
      }
      const material = this.pouchInputData.materials.filter(o => o.id === this.cardPaperActive)

      if (material.length > 0) {
        this.microns = material[0].microns.map((o) => {
          return {
            value: parseInt(o).toString(),
            text: parseInt(o).toString() + 'mi'
          }
        })
        this.micronSelected = this.microns[0].value
      }
      const paper = this.papers.filter(o => o.id === 82)
      this.cardPaperActive = paper[0].id
      this.cardPaperActiveName = paper[0].name_th
    }

    if (this.productData && this.productData.l > 0 && this.productData.w > 0) {
      this.amount = this.productData.amount //  no auto
      const smeJson = JSON.parse(this.productData.sme_json)
      this.w = smeJson.w
      this.l = smeJson.l
      this.h = smeJson.h ? smeJson.h : 0
      if (this.mapping_inputs.id === 24) {
        this.diameter = this.w
      }
      this.wldSelected = `${this.w},${this.l},${this.h}`

      if (smeJson && smeJson.patterns) {
        if (smeJson.patterns.length > 0) {
          if ('paper_type_id' in smeJson.patterns[0]) {
            this.cardPaperActive = parseInt(smeJson.patterns[0].paper_type_id)
          } else {
            this.cardPaperActive = parseInt(smeJson.paper_type_id)
          }
          this.papers.forEach((paper) => {
            if (paper.id === this.cardPaperActive) {
              this.cardPaperActiveName = paper.name_th
            }
          })
        }
        smeJson.patterns.forEach((pattern, index) => {
          this.patterns[index].enamel_id = parseInt(pattern.enamel_id)
          if ('color' in smeJson.patterns[0]) {
            this.patterns[index].color = parseInt(smeJson.patterns[0].color)
          } else {
            this.patterns[index].color = parseInt(smeJson.color)
          }
        })
        if ('stdSize' in this.sampleProductData) {
          this.paperSizeSelected = 'normal'
        } else {
          this.paperSizeSelected = 'custom'
        }
        if (this.mapping_inputs.id === 57 || this.mapping_inputs.id === 58) {
          this.patterns[0].special_technic_id = [61]
        }
      }

      this.changePaperColor()
      this.selectColor()
    } else if (this.getOrder2LocalStorage(this.orderKeyRef)) {
      this.loadOrderDataFromLocalStorage()
    } else {
      this.deliveryDate = this.minDate
      if (this.wldList.length > 0 && this.paperSizeSelected === 'normal') {
        const wld = this.wldList[0]
        this.wldSelected = `${wld.w},${wld.l},${wld.h}`
        this.w = wld.w
        this.l = wld.l
        this.h = wld.h
      }
    }

    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.iframeHeight = h - 10

    if (this.query.standardSizeId) {
      const standardSize = this.wldList.filter((o) => {
        return parseInt(o.id) === parseInt(this.query.standardSizeId)
      })[0]
      this.wldSelected = `${standardSize.w},${standardSize.l},${standardSize.h}`
      this.w = standardSize.w
      this.l = standardSize.l
      this.h = standardSize.h

      this.design = false
      this.useDesignTool = true
      this.provideTemplate = false
    }

    if (this.$route.query.hasDesign) {
      this.hasDesign = true
      this.uuid = this.$route.query.uuid
      this.design = false
      this.useDesignTool = true
      this.provideTemplate = false
      this.w = this.userDesign.w / 10
      this.l = this.userDesign.l / 10
      this.h = this.userDesign.h / 10
      this.wldSelected = `${this.w},${this.l},${this.h}`
      this.paperSizeSelected = this.userDesign.select_size_type === 0 ? 'normal' : 'custom'
      this.cardPaperActive = this.userDesign.paper_id
      this.mainPatternActive.paper_id = this.cardPaperActive
      this.toggleUseDesignTool()
    }

    this.waypointReleteLoaded = false

    this.mainPatternActive.paper_id = this.cardPaperActive
    if (this.patterns.length === 1) {
      this.mainPatternActive.color = this.patterns[0].color
      this.mainPatternActive.enamel_id = this.patterns[0].enamel_id
      this.mainPatternActive.color = this.patterns[0].color
      this.setPreview(this.patterns[0])
    } else {
      this.setPreview()
    }

    if (this.isAuthenticated && this.useDesignTool === true) {
      if (parseInt(this.query.standardSizeId) > 0) {
        this.getFirstDesign()
      } else {
        this.getLastDesign()
      }
    }
    if (
      this.query.semiDesignEditMode &&
      this.query.semiDesignEditMode.length > 0
    ) {
      // this.$cookies.set('semiDesignEditMode', this.query.semiDesignEditMode)
      this.setCookie({ cookieName: 'semiDesignEditMode', cookieValue: this.query.semiDesignEditMode })
      this.openModalDesigner()
    } else {
      this.$cookies.remove('semiDesignEditMode')
      this.$cookies.remove('semiDesignData')
    }
  },

  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading',
      'setAlert',
      'setAlertWithLine',
      'showLoadingCheckPrice',
      'hideLoadingCheckPrice'
    ]),
    ...mapActions({
      setCookie: 'setCookie',
      getCookie: 'getCookie'
    }),
    linkToStandard (e) {
      e.preventDefault()
      const tech = this.specialTechnicsLookUp(this.patterns[0].special_technic_id).trim()
      // console.log('id => ', this.id, tech, typeof this.id, typeof tech)
      if (this.id === '76' && tech === 'ติดซิบ') {
        // console.log('con1')
        this.linkTo['76'] = 749
      }

      if (this.id === '80' && tech === 'ติดซิบ') {
        // console.log('con2')
        this.linkTo['80'] = 465
      }
      // console.log('link to standard', this.id, linkTo)
      // this.$router.push(this.localePath({ name: 'products-detail-id', params: { id: item.id } }))
      window.open(this.localePath({ name: 'products-detail-id', params: { id: this.linkTo[this.id] } }), '_blank')
    },
    expandMe (n) {
      const tmpArr = []
      for (let i = 1; i <= 3; i++) {
        if (n === i && this.expandPrice[i - 1] === false) {
          tmpArr[i - 1] = true
        } else {
          tmpArr[i - 1] = false
        }
      }
      this.expandPrice = tmpArr
    },
    closeCookieConsent () {
      if (window.scgpdpaCookie) {
        window.scgpdpaCookie.hide()
      }
    },
    getFirstDesign () {
      if (
        // this.$checkCanFreeDesign(this.mapping_inputs.id) &&
        [2, 3].includes(this.mapping_inputs.get_template_type) &&
        this.isAuthenticated
      ) {
        const _this = this
        this.loadingLastDesign = true
        let w = this.w
        let l = this.l
        let h = this.h
        if (this.paperSizeSelected === 'normal') {
          for (const k in this.wldList) {
            const wld = this.wldList[k]
            const selectVal = `${wld.w},${wld.l},${wld.h}`
            if (this.wldSelected === selectVal) {
              if ('w_for_calculate' in wld && wld.w_for_calculate) {
                w = wld.w_for_calculate
              }
              if ('l_for_calculate' in wld && wld.l_for_calculate) {
                l = wld.l_for_calculate
              }
              if ('h_for_calculate' in wld && wld.h_for_calculate) {
                h = wld.h_for_calculate
              }
            }
          }
        }
        const parameter = {
          pattern_id: this.mapping_inputs.id,
          w: w * 10,
          l: l * 10,
          h: h * 10,
          'my-drawing': 1,
          'new-order': 1
        }
        if (parseInt(this.query.standardSizeId) > 0) {
          parameter['new-design'] = 1
        }
        if (w > 0 && l > 0) {
          if ((this.packagingTypeId === 1 || this.packagingTypeId === 3) && h === 0) {
            this.designData = {}
            _this.loadingLastDesign = false
            return false
          } else {
            this.$axios.get('designs', { params: parameter }).then((res) => {
              if (res.data && res.data.data.current) {
                this.designData = res.data.data.current
                _this.uuid = this.designData.uuid
                if (_this.uuid !== '' && this.query.semiDesignEditMode) { // no need force mode in other case
                  _this.useDesignTool = true
                  _this.provideTemplate = false
                }
              } else {
                this.designData = {}
              }
              _this.loadingLastDesign = false
            })
          }
        } else {
          this.designData = {}
          _this.loadingLastDesign = false
          return false
        }
      }
    },
    async getLastDesign () {
      if (
        // this.$checkCanFreeDesign(this.mapping_inputs.id) &&
        [2, 3].includes(this.mapping_inputs.get_template_type) &&
        this.isAuthenticated
      ) {
        const _this = this
        this.loadingLastDesign = true
        let w = this.w
        let l = this.l
        let h = this.h
        if (this.paperSizeSelected === 'normal') {
          for (const k in this.wldList) {
            const wld = this.wldList[k]
            const selectVal = `${wld.w},${wld.l},${wld.h}`
            if (this.wldSelected === selectVal) {
              if ('w_for_calculate' in wld && wld.w_for_calculate) {
                w = wld.w_for_calculate
              }
              if ('l_for_calculate' in wld && wld.l_for_calculate) {
                l = wld.l_for_calculate
              }
              if ('h_for_calculate' in wld && wld.h_for_calculate) {
                h = wld.h_for_calculate
              }
            }
          }
        }
        if (w > 0 && l > 0) {
          if ((this.packagingTypeId === 1 || this.packagingTypeId === 3) && h === 0) {
            this.designData = {}
            _this.loadingLastDesign = false
            return false
          } else {
            const patternId = this.patternSelected
            const flute = await this.$getFlute(this.mainPatternActive.paper_id)
            const res = await this.$axios.$get('/designs/get-designs-with-size', {
              params: {
                mapping_input_id: patternId,
                w: this.w * 10,
                l: this.l * 10,
                h: this.h * 10,
                s: this.s * 10,
                b: this.b * 10,
                flute,
                paper_id: this.mainPatternActive.paper_id
              }
            })

            const userDesigns = res.data
            if (userDesigns.length > 0) {
              this.hasDesign = true
              this.uuid = userDesigns[0] ? userDesigns[0].uuid : null
              this.useDesignTool = true
              this.provideTemplate = false
            } else {
              this.hasDesign = false
              this.uuid = null
              this.designData = {}
            }
            this.loadingLastDesign = false
          }
        } else {
          this.designData = {}
          _this.loadingLastDesign = false
          return false
        }
      }
    },
    changeTagHold (val) {
      if (
        'special_technic_id' in this.patterns[0] &&
        this.patterns[0].special_technic_id.length > 0
      ) {
        const newData = []
        for (const i in this.patterns[0].special_technic_id) {
          if (
            (this.patterns[0].special_technic_id[i] === 61 && val === 62) ||
            (this.patterns[0].special_technic_id[i] === 62 && val === 61)
          ) {
            newData.push(val)
          } else if (
            this.patterns[0].special_technic_id[i] !== 61 &&
            this.patterns[0].special_technic_id[i] !== 62
          ) {
            newData.push(this.patterns[0].special_technic_id[i])
          }
        }
        this.patterns[0].special_technic_id = newData
      } else {
        this.patterns[0].special_technic_id = [val]
      }
      this.specialTechniqueTagHoldId = val
    },
    gotoOtherPattern (id, productId) {
      if (id !== this.mapping_inputs.id) {
        this.showLoading()
      }
      this.$router.push(this.localeLocation({
        name: 'customize-product-design-id',
        params: { design: true, id },
        query: { productId }
      }))
    },
    canDesignOnline (mappingInputs) {
      if ([2, 3].includes(mappingInputs.get_template_type)) {
        return true
      } else {
        return false
      }
    },
    toggleProvideTemplate () {
      this.clearFactory()
      // if (this.canDesignOnline(this.mapping_inputs)) {
      if (this.provideTemplate === true) {
        this.useDesignTool = false
        this.design = false
        if (this.patterns[0].color === 0) {
          this.patterns[0].color = 1
        }
      } else if (this.patterns[0].color > 0) {
        this.patterns[0].color = 0
      }
      // }
    },
    toggleDesignMode () {
      this.clearFactory()
      // if (this.canDesignOnline(this.mapping_inputs)) {
      if (this.design === true) {
        this.useDesignTool = false
        this.provideTemplate = false
        if (this.patterns[0].color === 0) {
          this.patterns[0].color = 1
        }
      } else if (this.patterns[0].color > 0) {
        this.patterns[0].color = 0
      }
      // }
    },
    toggleUseDesignTool () {
      this.clearFactory()
      if (this.canDesignOnline(this.mapping_inputs)) {
        if (this.useDesignTool === true) {
          this.design = false
          this.provideTemplate = false
          if (this.mapping_inputs.packaging_type_id === 3) {
            this.patterns[0].color = 4
          } else {
            this.patterns[0].color = 1
          }
          if (this.packagingTypeId === 1) {
            this.patterns[0].enamel_id = 17
          } else if (this.packagingTypeId === 3) {
            this.patterns[0].enamel_id = 39
          }
          this.getLastDesign()
        } else if (this.patterns[0].color > 0) {
          this.patterns[0].color = 0
        }
      }
    },
    toggleDiscountCodeTemplate () {
      if (this.discountCodeTemplate === true) {
        this.redeemPointTemplate = false
        localStorage.setItem('discountCodeTemplate', true)
        localStorage.setItem('redeemPointTemplate', false)
      }
    },
    toggleRedeemPointTemplate () {
      if (this.redeemPointTemplate === true) {
        this.discountCodeTemplate = false
        localStorage.setItem('discountCodeTemplate', false)
        localStorage.setItem('redeemPointTemplate', true)
        this.promoCodeSelect = null
        this.promoCodeTxt = ''

        if (this.isAuthenticated) {
          try {
            this.$axios.$get('point-users/' + this.userData.uid + '/point').then((res) => {
              if (res.total_point < this.reward.points) {
                this.setAlert({
                  show: true,
                  message: 'พอยท์ที่มีอยู่ ไม่พอที่จะใช้งาน',
                  header: 'แจ้งเตือน'
                })
                this.redeemPointTemplate = false
                localStorage.setItem('redeemPointTemplate', false)
              }
            })
          } catch (err) {
            this.setAlert({
              show: true,
              message: err.message,
              header: 'แจ้งเตือน'
            })
            this.hideLoading()
          }
        }
      }
    },
    openVideo (videoUrl) {
      if (videoUrl) {
        window.open(videoUrl, '_blank', 'width=720,height=680')
      }
    },
    setDrawingData (data) {
      this.drawingData = data
    },
    setDrawingPNG (data) {
      this.drawingPNG = data
    },
    getTypeText () {
      if (this.mapping_inputs.packaging_type_id === 2) {
        return 'สติกเกอร์'
      } else if (this.mapping_inputs.packaging_type_id === 6) {
        return 'ซอง'
      } else {
        return 'กล่อง'
      }
    },
    setPreview (pattern = null) {
      if (pattern) {
        this.mainPatternActive.color = pattern.color
        this.mainPatternActive.enamel_id = pattern.enamel_id
      }
      if (this.useDesignTool === true) {
        if (this.packagingTypeId === 1 && pattern.enamel_id !== 17) {
          this.useDesignTool = false
          this.provideTemplate = true
        } else if (this.packagingTypeId === 3 && pattern.enamel_id !== 39) {
          this.useDesignTool = false
          this.provideTemplate = true
        }
      }
      if (this.patterns.length === 1) {
        if (this.mainPatternActive.color === 0) {
          const index = this.mainPatternActive.paper_id + '-0-n'
          this.previewList = this.imagesets[index]
        } else {
          const enamelId =
            this.mapping_inputs.packaging_type_id === 3
              ? this.mainPatternActive.enamel_id
              : 'n'
          const color =
            this.mapping_inputs.packaging_type_id === 3
              ? this.mainPatternActive.color
              : 4
          const index =
            this.mainPatternActive.paper_id + '-' + color + '-' + enamelId
          this.previewList = this.imagesets[index]
        }
      } else {
        if (this.loadCombinationFirst === 1) {
          if (pattern) {
            let color = null
            if (pattern.color === 0) {
              color = 0
            } else {
              color =
                this.mapping_inputs.packaging_type_id === 3 ? pattern.color : 4
            }
            let enamelId = null
            if (color === 0) {
              enamelId = 'n'
            } else {
              enamelId =
                this.mapping_inputs.packaging_type_id === 3
                  ? pattern.enamel_id
                  : 'n'
            }
            const sub = pattern.sub_id + '-' + color + '-' + enamelId
            this.mainPatternIndexActive[pattern.sub_id] = sub
            this.patternIndex[pattern.sub_id] = pattern
            const index =
              this.mainPatternActive.paper_id +
              '-' +
              this.mainPatternIndexActive[this.patterns[0].sub_id] +
              '-' +
              this.mainPatternIndexActive[this.patterns[1].sub_id]
            this.previewList = this.imagesets[index]
          } else {
            let enamelId = null
            let color = null
            if (this.patternIndex[this.patterns[0].sub_id].color === 0) {
              color = 0
            } else {
              color =
                this.mapping_inputs.packaging_type_id === 3
                  ? this.patternIndex[this.patterns[0].sub_id].color
                  : 4
            }
            if (color === 0) {
              enamelId = 'n'
            } else {
              enamelId =
                this.mapping_inputs.packaging_type_id === 3
                  ? this.patternIndex[this.patterns[0].sub_id].enamel_id
                  : 'n'
            }
            const sub1 =
              this.patternIndex[this.patterns[0].sub_id].sub_id +
              '-' +
              color +
              '-' +
              enamelId
            this.mainPatternIndexActive[this.patterns[0].sub_id] = sub1
            if (this.patternIndex[this.patterns[1].sub_id].color === 0) {
              color = 0
            } else {
              color =
                this.mapping_inputs.packaging_type_id === 3
                  ? this.patternIndex[this.patterns[1].sub_id].color
                  : 4
            }
            if (color === 0) {
              enamelId = 'n'
            } else {
              enamelId =
                this.mapping_inputs.packaging_type_id === 3
                  ? this.patternIndex[this.patterns[1].sub_id].enamel_id
                  : 'n'
            }
            const sub2 =
              this.patternIndex[this.patterns[1].sub_id].sub_id +
              '-' +
              color +
              '-' +
              enamelId
            this.mainPatternIndexActive[this.patterns[1].sub_id] = sub2
            const index =
              this.mainPatternActive.paper_id + '-' + sub1 + '-' + sub2
            this.previewList = this.imagesets[index]
          }
        } else {
          let color = null
          if (this.patterns[0].color === 0) {
            color = 0
          } else {
            color =
              this.mapping_inputs.packaging_type_id === 3
                ? this.patterns[0].color
                : 4
          }
          let enamelId = null
          if (color === 0) {
            enamelId = 'n'
          } else {
            enamelId =
              this.mapping_inputs.packaging_type_id === 3
                ? this.patterns[0].enamel_id
                : 'n'
          }
          const sub1 = this.patterns[0].sub_id + '-' + color + '-' + enamelId
          this.mainPatternIndexActive[this.patterns[0].sub_id] = sub1
          this.patternIndex[this.patterns[0].sub_id] = this.patterns[0]
          if (this.patterns[1].color === 0) {
            color = 0
          } else {
            color =
              this.mapping_inputs.packaging_type_id === 3
                ? this.patterns[1].color
                : 4
          }
          if (color === 0) {
            enamelId = 'n'
          } else {
            enamelId =
              this.mapping_inputs.packaging_type_id === 3
                ? this.patterns[1].enamel_id
                : 'n'
          }
          const sub2 = this.patterns[1].sub_id + '-' + color + '-' + enamelId
          this.mainPatternIndexActive[this.patterns[1].sub_id] = sub2
          this.patternIndex[this.patterns[1].sub_id] = this.patterns[1]
          const index =
            this.mainPatternActive.paper_id + '-' + sub1 + '-' + sub2
          this.previewList = this.imagesets[index]
        }
        this.loadCombinationFirst = 1
      }
      this.previewList = this.previewList === undefined ? [] : this.previewList
      if (this.previewList.length === 0) {
        this.previewList.push({
          type: 'open_image',
          path: 'product_set_image_open/default-image.png'
        })
      }
      this.setPreviewDefaultImage()
    },
    setPreviewDefaultImage () {
      const img = this.previewList.filter(obj => obj.type === 'open_image')
      if (img.length > 0) {
        const path = this.$store.state.aws_s3 + img[0].path
        this.default_img = path
      } else {
        this.default_img =
          this.$store.state.aws_s3 + 'product_set_image_open/default-image.png'
      }
    },
    changeMaterial () {
      if (this.mapping_inputs.packaging_type_id === 6) {
        const material = this.pouchInputData.materials.find(o => o.id === this.cardPaperActive)
        if (material) {
          this.microns = material.microns.map((o) => {
            return {
              value: parseInt(o).toString(),
              text: parseInt(o).toString() + 'mi'
            }
          })
          this.micronSelected = this.microns[0].value
        }
      }
    },
    changePaperColor () {
      if ([74, 75].includes(this.cardPaperActive)) {
        this.paperColor = 'whitewhite'
      } else if ([34, 54].includes(this.cardPaperActive)) {
        this.paperColor = 'white'
      } else {
        this.paperColor = 'brown'
      }
      if (
        this.cardPaperActive === 15 &&
        (this.packagingTypeId === 1 || this.packagingTypeId === 5)
      ) {
        this.paperGramSelected = 385
      } else if (this.packagingTypeId === 1 || this.packagingTypeId === 5) {
        this.paperGramSelected = 350
      }
      if (
        this.useDesignTool === true &&
        this.design === false &&
        this.provideTemplate === false &&
        this.colorPrintActive > 0
      ) {
        this.provideTemplate = true
        this.useDesignTool = false
      }
      this.filterSampleImage()
    },
    filterSampleImage () {
      const retData = []
      for (const index in this.sampleProducts) {
        let paperTypeId = 0
        let colorPrint = 1
        if (
          this.sampleProducts[index].sme_json &&
          this.sampleProducts[index].sme_json.patterns.length > 0
        ) {
          if ('paper_type_id' in this.sampleProducts[index].sme_json) {
            paperTypeId = parseInt(
              this.sampleProducts[index].sme_json.paper_type_id
            )
          }
          if (
            'paper_type_id' in this.sampleProducts[index].sme_json.patterns[0]
          ) {
            paperTypeId = parseInt(
              this.sampleProducts[index].sme_json.patterns[0].paper_type_id
            )
          }
          if ('color' in this.sampleProducts[index].sme_json.patterns[0]) {
            colorPrint = parseInt(
              this.sampleProducts[index].sme_json.patterns[0].color
            )
          }
        }
        if (
          this.cardPaperActive === paperTypeId &&
          colorPrint === this.patterns[0].color
        ) {
          if (this.sampleProducts[index].img.length > 2) {
            this.sampleProducts[index].img = this.sampleProducts[
              index
            ].img.slice(0, 2)
          }
          retData.push(this.sampleProducts[index])
        }
      }
      this.sampleProductImages = retData
    },
    async  openModalMyDesigns () {
      if (this.isAuthenticated) {
        let flute = null
        if (![57, 58, 59, 60, 66, 67].includes(this.patternSelected)) {
          flute = flute = await this.$getFlute(this.mainPatternActive.paper_id)
        }
        const wForCalculate = this.w_for_calculate && this.w_for_calculate > 0 ? this.w_for_calculate : this.w
        const lForCalculate = this.l_for_calculate && this.l_for_calculate > 0 ? this.l_for_calculate : this.l
        const hForCalculate = this.h_for_calculate && this.h_for_calculate > 0 ? this.h_for_calculate : this.h
        await this.$refs.myDesignModal.getMyDesigns({
          mapping_input_id: this.patternSelected,
          w: this.w * 10,
          l: this.l * 10,
          h: this.h * 10,
          s: this.s * 10,
          b: this.b * 10,
          w_for_calculate: wForCalculate * 10,
          l_for_calculate: lForCalculate * 10,
          h_for_calculate: hForCalculate * 10,
          flute,
          select_size_type: this.paperSizeSelected === 'normal' ? 0 : 1,
          design_type: 'normal',
          r: this.r,
          dim: this.dim,
          paper_id: this.mainPatternActive.paper_id
        })
        this.hideLoading()
        this.$refs.myDesignModal.show = true
        const paperName = (this.packagingTypeId === 1) ? this.showNameId(this.papers, this.cardPaperActive) + ` ${this.paperGramSelected} แกรม` : this.showNameId(this.papers, this.cardPaperActive)
        if (this.packagingTypeId === 6) {
          this.$refs.myDesignModal.subHeader = `${this.mapping_inputs.name_th} ${this.w} x ${this.l} ซม. ${this.s ? 'ขนาดก้นซองรวมรอยซีล ' + this.s : ''}${this.b ? 'ขนาดข้างซองรวมรอยซีล ' + this.b : ''} ${paperName}`
        } else {
          this.$refs.myDesignModal.subHeader = `${this.mapping_inputs.name_th} ${this.w} x ${this.l} x ${this.h} ซม. ${paperName}`
        }
      }
    },
    openMyDesignModal (design) {
      this.showLoading()
      this.uuid = design.uuid
      this.$bvModal.show('modalDesigner')
    },
    orderDesign (design) {
      this.uuid = design.uuid
      this.useDesignTool = true
      this.hasDesign = true
    },
    async openModalDesigner () {
      if (Number(this.w) > 0 && Number(this.l) > 0) {
        if ((this.packagingTypeId === 1 || this.packagingTypeId === 3) && Number(this.h) === 0) {
          this.setAlert({
            show: true,
            message: 'กรุณาระบุขนาดกล่อง',
            header: 'แจ้งเตือน'
          })
          return false
        }
      } else {
        this.setAlert({
          show: true,
          message: 'กรุณาระบุขนาดกล่อง',
          header: 'แจ้งเตือน'
        })
        return false
      }
      await this.checkPriceFactory()
      if (this.factoryList.length === 0) {
        return false
      }
      if (!this.isAuthenticated) {
        const dataStore = {
          params: this.prepareData(true),
          factorySelected: this.factorySelected,
          fromModalDesigner: true
        }
        this.storeOrder2LocalStorage(dataStore)
        localStorage.setItem(
          'click-login-from-page',
          'customize-product-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ design: true, id: this.patternSelected })
        )

          if (liff.getLineVersion() !== null) {
            const strUrlConcat = (window.location.href.includes('?')) ? '&' : '?'
            liff.openWindow({
              url: window.location.href + strUrlConcat + 'openExternalBrowser=1',
              external: true
            })
          } else {
            localStorage.setItem('after-login-then', 'opendesigner')
            this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
          }
      } else {
        this.showLoading()
        const patternId = this.patternSelected
        let flute = null
        if (![57, 58, 59, 60, 66, 67].includes(patternId)) {
          flute = await this.$getFlute(this.mainPatternActive.paper_id)
        }
        const res = await this.$axios.$get('/designs/get-designs-with-size', {
          params: {
            mapping_input_id: patternId,
            w: this.w * 10,
            l: this.l * 10,
            h: this.h * 10,
            s: this.s * 10,
            b: this.b * 10,
            flute,
            paper_id: this.mainPatternActive.paper_id
          }
        })
        const userDesigns = res.data
        if (patternId === 57 || patternId === 58) {
          this.r = 5
          this.dim = 3
          if (this.specialTechniqueTagHoldId === 61) {
            this.dim = 3
          }
          if (this.specialTechniqueTagHoldId === 62) {
            this.dim = 6
          }
        }
        if (patternId === 59 || patternId === 66) {
          this.r = 0
          this.dim = 0
        }
        if (this.paperSizeSelected === 'normal') {
          for (const k in this.wldList) {
            const wld = this.wldList[k]
            const selectVal = `${wld.w},${wld.l},${wld.h}`
            if (this.wldSelected === selectVal) {
              if ('w_for_calculate' in wld && wld.w_for_calculate) {
                this.w_for_calculate = wld.w_for_calculate
              }
              if ('l_for_calculate' in wld && wld.l_for_calculate) {
                this.l_for_calculate = wld.l_for_calculate
              }
              if ('h_for_calculate' in wld && wld.h_for_calculate) {
                this.h_for_calculate = wld.h_for_calculate
              }
            }
          }
        } else {
          this.w_for_calculate = this.w
          this.l_for_calculate = this.l
          this.h_for_calculate = this.h
        }
        if (userDesigns.length > 0) {
          this.openModalMyDesigns()
        } else {
          const wForCalculate = this.w_for_calculate && this.w_for_calculate > 0 ? this.w_for_calculate : this.w
          const lForCalculate = this.l_for_calculate && this.l_for_calculate > 0 ? this.l_for_calculate : this.l
          const hForCalculate = this.h_for_calculate && this.h_for_calculate > 0 ? this.h_for_calculate : this.h
          const userDesign = await this.$axios.$post('/designs/create-designs', {
            mapping_input_id: patternId,
            w: this.w * 10,
            l: this.l * 10,
            h: this.h * 10,
            s: this.s * 10,
            b: this.b * 10,
            w_for_calculate: wForCalculate * 10,
            l_for_calculate: lForCalculate * 10,
            h_for_calculate: hForCalculate * 10,
            flute,
            select_size_type: this.paperSizeSelected === 'normal' ? 0 : 1,
            design_type: 'normal',
            r: this.r,
            dim: this.dim,
            paper_id: this.mainPatternActive.paper_id
          })
          this.uuid = userDesign.data.uuid
          this.hasDesign = true
          this.$bvModal.show('modalDesigner')
        }
      }
    },
    imgCorrugate (i, pattern = null) {
      let paperType = 'brown'
      if ((this.cardPaperActive === 74 || this.cardPaperActive === 75) && this.packagingTypeId === 3) {
        paperType = 'whitewhite'
      } else if ((this.cardPaperActive === 34 || this.cardPaperActive === 54) && this.packagingTypeId === 3) {
        paperType = 'white'
      } else {
        paperType = 'brown'
      }
      if (pattern === null && paperType in this.enamelImgCorrugate) {
        const img = this.enamelImgCorrugate[paperType][this.colorPrintActive]
          .area[i]
        return this.$imgProxy.getUrl(this.$store.state.aws_s3 + img, 400, 0)
      } else if (
        pattern !== null &&
        pattern.sub_id in this.enamelImgCorrugate
      ) {
        const img = this.enamelImgCorrugate[pattern.sub_id][this.paperColor][
          pattern.color
        ].area[i]
        return this.$imgProxy.getUrl(this.$store.state.aws_s3 + img, 400, 0)
      }
    },
    selectColor (pattern) {
      if (this.packagingTypeId === 3) {
        this.changePaperColor()
      }
      this.setPreview(pattern)
      if (this.patterns[0].color === 0) {
        this.useDesignTool = false
        this.design = false
        this.provideTemplate = false
      }
    },
    showNameId (data, id) {
      if (data.length > 0) {
        const item = data.filter(x => x.id === id)

        if ('0' in item && item[0].name_th) {
          return item[0].name_th
        }
        if ('0' in item && item[0].name) {
          return item[0].name
        }
      } else {
        return ''
      }
    },
    scrollTo (ref) {
      if (this.showEdit) {
        setTimeout(() => {
          ref.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }
    },
    scrollToCheckPrice (ref) {
      setTimeout(() => {
        ref.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    },
    factoryPicExample (factory, id, totalPrice) {
      this.$refs.factoryExample.imgs = this.factoryPic[id]
      this.$refs.factoryExample.factory = factory
      this.$refs.factoryExample.show = true
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'requestSample',
        productName: this.previewProduct[0].name,
        supplier: 'โรงงาน ' + id,
        value: totalPrice
      })
    },
    imgZoom (img) {
      this.$refs.imgZoom.img = img
      this.$refs.imgZoom.show = true
    },
    showDimensionSlide () {
      this.$refs.modalDimensionSlide.show()
    },
    imgSlide (imgs, videos) {
      this.$refs.imgSlide.imgs = imgs
      this.$refs.imgSlide.videos = videos
      this.$refs.imgSlide.show = true
    },
    gotoSampleproduct (sampleProduct) {
      this.$router.push(this.localeLocation({
        name: 'sample-product-id',
        params: { id: sampleProduct.id }
      }))
    },
    sampleProductPreview (sampleProduct) {
      this.$refs.sampleProductPreview.sampleProduct = sampleProduct
      this.$refs.sampleProductPreview.show = true
    },
    enamelsLookUp (enamelsId) {
      if (this.enamels.length > 0 && enamelsId) {
        let i = 0
        for (; i < this.enamels.length; i++) {
          if (enamelsId === this.enamels[i].id) {
            return this.enamels[i].name_th
          }
        }
      }
      return ''
    },
    spoutLookup (spoutId) {
      if (this.pouchInputData && this.pouchInputData.spouts) {
        const spout = this.pouchInputData.spouts.filter(o => o.id === spoutId)
        return spout.length > 0 ? 'Spout ' + spout[0].name : ''
      }
      return ''
    },
    specialTechnicsLookUp (specialTechnic) {
      let str = ''
      if (this.special_technics.length > 0 && specialTechnic.length > 0) {
        let k = 0
        for (; k < specialTechnic.length; k++) {
          let i = 0
          for (; i < this.special_technics.length; i++) {
            if (specialTechnic[k] === this.special_technics[i].id) {
              str += this.special_technics[i].name_th + ' '
            }
          }
        }
      }
      return str
    },
    storeOrder2LocalStorage (orderData) {
      if (process.client) {
        localStorage.setItem(this.orderKeyRef, JSON.stringify(orderData))
      }
    },
    getOrder2LocalStorage (orderData) {
      if (process.client) {
        return JSON.parse(localStorage.getItem(this.orderKeyRef))
      }
    },
    checkAmount () {
      if (
        this.amount < 100 ||
        (this.amount2 !== '' && this.amount2 < 100) ||
        (this.amount3 !== '' && this.amount3 < 100)
      ) {
        this.setAlertWithLine({
          show: true,
          message: 'จำนวนกล่องขั้นต่ำ 100 ชิ้น',
          header: 'แจ้งเตือน'
        })
        this.amount = 100
      }
      if (this.mapping_inputs.packaging_type_id !== 6) {
        if (
          this.amount > 10000 ||
          (this.amount2 !== '' && this.amount2 > 10000) ||
          (this.amount3 !== '' && this.amount3 > 10000)
        ) {
          this.setAlertWithLine({
            show: true,
            message: 'จำนวนเกิน 10,000 ชิ้น กรุณาติดต่อเราที่ line @locopack',
            header: 'แจ้งเตือน'
          })
          this.amount = 10000
        }
      }
      if (this.amount < 1000) {
        this.patterns.forEach((pattern) => {
          if (!([57, 58].includes(this.mapping_inputs.id) && pattern.special_technic_id.length === 1 && pattern.special_technic_id[0] === 61)) {
            if (pattern.special_technic_id.length > 0 && (![57, 58].includes(this.mapping_inputs.id))) {
              this.setAlertWithLine({
                show: true,
                message: 'จำนวนสั่งขั้นต่ำเมื่อเลือกเทคนิคพิเศษ คือ 1,000 ชิ้น',
                header: 'แจ้งเตือน'
              })
              this.amount = 1000
              return false
            }
          }
        })
      }
    },
    clearFactory () {
      this.factoryList = []
      this.factoryListOther = []
      this.expandPrice = [false, false, false]
      this.amountOther = [0, 0, 0]
      this.factorySelected = null
      this.totalResult = null
      if (this.zipCode.length === 5) {
        this.$refs.tooltip.$emit('close')
        this.$refs.tooltip.$emit('disable')
      } else {
        this.$refs.tooltip.$emit('open')
        this.$refs.tooltip.$emit('enable')
      }
    },
    prepareData (withDrawing) {
      const postPatterns = []
      this.patterns.forEach((pattern) => {
        postPatterns.push({
          packaging_type_id: pattern.packaging_type_id,
          paper_type_id: this.cardPaperActive,
          paper_gsm: this.paperGramSelected,
          enamel_id: pattern.enamel_id,
          pattern_id: pattern.id,
          sub_id: pattern.sub_id,
          special_technic_id: pattern.special_technic_id,
          color: pattern.color
        })
      })

      let promoCode = null
      // let rewardPoint = null
      let rewardData = []
      let discountType = 0
      if (this.promoCodeSelect != null && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
        promoCode = this.promoCodeSelect.code
        discountType = 1
      } else if (this.promoCodeTxt !== '' && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
        promoCode = this.promoCodeTxt
        discountType = 1
      } else if (this.reward.points !== '' && (this.redeemPointTemplate === true || localStorage.getItem('redeemPointTemplate') === true)) {
        discountType = 3
        rewardData = this.reward
      }

      localStorage.setItem('myZipcode', this.zipCode)
      const paperName = (this.packagingTypeId === 1) ? this.showNameId(this.papers, this.cardPaperActive) + ` ${this.paperGramSelected} แกรม` : this.showNameId(this.papers, this.cardPaperActive)
      let firebaseUid = null
      if (this.isAuthenticated) {
        firebaseUid = this.userData.uid
      }
      const data = {
        firebase_uid: firebaseUid,
        type: 0,
        mapping_pattern_id: this.mapping_inputs.id,
        pattern_id: this.mapping_inputs.id,
        patterns: postPatterns,
        amount: this.amount,
        amount2: this.amount2,
        amount3: this.amount3,
        w: this.w,
        l: this.l,
        h: this.h,
        s: this.s,
        b: this.b,
        color: this.colorPrintActive,
        zipcode: this.zipCode,
        mapping_inputs: this.mapping_inputs,
        cardPaperActive: this.cardPaperActive,
        material_id: this.cardPaperActive,
        micron: this.micronSelected,
        special_technic_id: this.patterns[0].special_technic_id,
        spout: this.spoutId,
        cardPaperActiveName: paperName,
        paperGramSelected: this.paperGramSelected,
        deliveryDate: this.deliveryDate,
        is_brief_design: this.design === true ? 1 : 0,
        design_by_yourself: this.useDesignTool === true ? 1 : 0,
        totalResult: this.totalResult,
        promotion_code: promoCode,
        paper_type_id: this.cardPaperActive,
        drawingData:
          typeof withDrawing !== 'undefined' && withDrawing === true
            ? this.drawingData
            : '',
        drawingPNG:
          typeof withDrawing !== 'undefined' && withDrawing === true
            ? this.drawingPNG
            : '',
        sample_product_categories_id:
          this.sampleProductData && this.sampleProductData.cat_id
            ? this.sampleProductData.cat_id
            : null,
        // points: rewardPoint,
        discount_type: discountType,
        reward_data: rewardData
      }

      return data
    },
    modalPaperColorCheckout () {
      this.$bvModal.hide('modalPaperColor')
      this.selectFactory(this.currentFactory)
      this.goToCheckout(false)
    },
    checkoutCheck (factory, amount, k) {
      this.selected_key = k
      if (typeof amount !== 'undefined' && amount !== null) {
        this.amount = amount
      }
      if (
        this.mapping_inputs.packaging_type_id === 3 &&
        this.colorPrintActive > 0
      ) {
        this.currentFactory = factory
        this.$bvModal.show('modalPaperColor')
      } else {
        this.selectFactory(factory)
        this.goToCheckout(false)
      }
    },
    async goToCheckout (reqQuotation) {
      const dataStore = {
        params: this.prepareData(true),
        factorySelected: this.factorySelected,
        fromModalDesigner: false
      }

      if (!this.isAuthenticated) {
        this.storeOrder2LocalStorage(dataStore)
        localStorage.setItem(
          'click-login-from-page',
          'customize-product-design-id'
        )
        localStorage.setItem(
          'click-login-from-page-params',
          JSON.stringify({ design: true, id: this.patternSelected })
        )
        localStorage.setItem(
          'click-login-from-page-query',
          JSON.stringify({ productId: this.query.productId })
        )
        localStorage.setItem('back_url', this.$route.path)
        if (liff.getLineVersion() !== null) {
          const strUrlConcat = (window.location.href.includes('?')) ? '&' : '?'
          liff.openWindow({
            url: window.location.href + strUrlConcat + 'openExternalBrowser=1',
            external: true
          })
        } else {
          localStorage.setItem('after-login-then', 'checkout')
          this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
        }
      } else {
        this.showLoading()
        let detailText = ''
        const nameOfProduct =
          this.sampleProductData &&
          this.paperSizeSelected === 'normal' &&
          'name' in this.sampleProductData
            ? this.sampleProductData.name
            : this.mapping_inputs.name_th
        if (this.mapping_inputs.packaging_type_id === 5) {
          detailText = `${nameOfProduct} x ${this.$formatPriceNumber(
            this.amount
          )} ชิ้น, ${this.w} x ${this.l} ซม., `
        } else {
          detailText = `${nameOfProduct} x ${this.$formatPriceNumber(
            this.amount
          )} ชิ้น, ${this.w} x ${this.l} x ${this.h} ซม., `
        }

        detailText =
          detailText +
          `${this.cardPaperActiveName} ${this.paperGramSelected} gsm, `
        if (this.patterns.length > 1) {
          this.patterns.forEach((pattern) => {
            detailText =
              detailText +
              `${pattern.name} ${
                pattern.color === 1 ? 'พิมพ์สี' : 'ไม่พิมพ์สี'
              } ${this.enamelsLookUp(pattern.enamel_id)}, `
          })
        } else {
          detailText =
            detailText +
            `${this.colorPrintActive === 1 ? 'พิมพ์สี' : 'ไม่พิมพ์สี'}, `
        }
        detailText = detailText + `รหัสไปรษณีย์จัดส่ง ${this.zipCode} `
        let optionDetail = ''
        if (this.mapping_inputs.packaging_type_id === 5) {
          optionDetail = `${this.w} x ${this.l} ซม. `
        } else {
          optionDetail = `${this.w} x ${this.l} x ${this.h} ซม. `
        }

        if (
          this.mapping_inputs.packaging_type_id === 1 ||
          this.mapping_inputs.packaging_type_id === 5
        ) {
          optionDetail += `${this.showNameId(
            this.papers,
            this.cardPaperActive
          )} ${this.paperGramSelected} แกรม `
        } else {
          optionDetail += `${this.showNameId(
            this.papers,
            this.cardPaperActive
          )} `
        }

        if (this.patterns.length === 1) {
          if (this.mapping_inputs.packaging_type_id !== 3) {
            optionDetail += `${
              this.colorPrintActive === 1
                ? 'พิมพ์ 4 สี เสมือนจริง'
                : 'ไม่พิมพ์สี'
            } ${this.enamelsLookUp(
              this.patterns[0].enamel_id
            )} ${this.specialTechnicsLookUp(
              this.patterns[0].special_technic_id
            )} `
          }
          if (this.mapping_inputs.packaging_type_id === 3) {
            optionDetail += `${
              this.colorText[this.mapping_inputs.packaging_type_id][
                this.colorPrintActive
              ]
            } ${this.enamelsLookUp(
              this.patterns[0].enamel_id
            )} ${this.specialTechnicsLookUp(
              this.patterns[0].special_technic_id
            )} `
          }
        } else {
          this.patterns.forEach((pattern, index) => {
            if (this.mapping_inputs.packaging_type_id !== 3) {
              optionDetail += `- ${pattern.name} ${
                pattern.color === 1 ? 'พิมพ์สี' : 'ไม่พิมพ์สี'
              } ${this.enamelsLookUp(
                this.patterns[index].enamel_id
              )} ${this.specialTechnicsLookUp(
                this.patterns[index].special_technic_id
              )} `
            }
            if (this.mapping_inputs.packaging_type_id === 3) {
              optionDetail += `- ${pattern.name} ${
                this.colorText[this.mapping_inputs.packaging_type_id][
                  this.colorPrintActive
                ]
              } ${this.enamelsLookUp(
                this.patterns[index].enamel_id
              )} ${this.specialTechnicsLookUp(
                this.patterns[index].special_technic_id
              )} `
            }
          })
        }
        let result = null
        if (this.mapping_inputs.packaging_type_id === 6) {
          let rewardData = []
          let promoCode = null
          let discountType = 0
          let firebaseUid = null
          if (this.isAuthenticated) {
            firebaseUid = this.userData.uid
          }

          if (this.promoCodeSelect != null && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
            promoCode = this.promoCodeSelect.code
            discountType = 1
          } else if (this.promoCodeTxt !== '' && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
            promoCode = this.promoCodeTxt
            discountType = 1
          } else if (this.reward.points !== '' && (this.redeemPointTemplate === true || localStorage.getItem('redeemPointTemplate') === true)) {
            discountType = 3
            rewardData = this.reward
          }

          const postDataPouch = {
            packaging_type_id: this.mapping_inputs.packaging_type_id,
            selected_key: this.selected_key ? this.selected_key : 0,
            input: {
              firebase_uid: firebaseUid,
              pattern_id: this.mapping_inputs.id,
              w: this.w,
              l: this.l,
              s: this.s,
              b: this.b,
              amount: this.amount,
              material_id: this.cardPaperActive,
              micron: this.micronSelected,
              special_technic_id: this.patterns[0].special_technic_id,
              spout: this.spoutId,
              zipcode: this.zipCode,
              promotion_code: promoCode,
              discount_type: discountType,
              reward_data: rewardData,
              is_brief_design: this.design === true ? 1 : 0
            },
            req_quotation_click: reqQuotation ? 1 : 0,
            uuid: this.uuid,
            is_brief_design: this.design === true ? 1 : 0,
            design_by_yourself: this.useDesignTool === true ? 1 : 0,
            utm_source: this.utmSource
          }

          result = await this.$axios.$post('orders-v2', postDataPouch)
          this.hideLoading()
        } else {
          const postData = {
            option_detail: optionDetail,
            detail_text: detailText,
            amount: this.amount,
            sum: this.totalResult.total,
            price_per_pcs: this.totalResult.per_pcs,
            print_plate_price: this.totalResult.plate,
            plate_diecut_price: this.totalResult.diecut_block,
            shipping_price: this.totalResult.delivery_price,
            require_date: this.deliveryDate,
            total: this.totalResult.total,
            product: this.prepareData(true),
            factory_id: this.totalResult.factory_id,
            print_type_id: this.totalResult.print_type_id,
            is_brief_design: this.design === true ? 1 : 0,
            design_by_yourself: this.useDesignTool === true ? 1 : 0,
            zipcode: this.zipCode,
            req_quotation_click: reqQuotation ? 1 : 0,
            uuid: this.uuid,
            utm_source: this.utmSource
          }

          result = await this.$axios.$post('orders', postData)
          this.hideLoading()
        }

        if (result.error === 'verifyEmail') {
          this.hideLoading()
          this.$refs.emailModal.show = true
          return false
        }
        if (result.status === 0 || result.status === false) {
          this.hideLoading()
          this.setAlertWithLine({
            show: true,
            message: result.msg,
            header: 'แจ้งเตือน'
          })
          return false
        }

        if (this.useDesignTool === true && this.hasDesign === false) {
          const patternId = this.patternSelected

          let flute = null
          if (![57, 58, 59, 60, 66, 67].includes(patternId)) {
            flute = await this.$getFlute(this.mainPatternActive.paper_id)
          }

          const wForCalculate = this.w_for_calculate && this.w_for_calculate > 0 ? this.w_for_calculate : this.w
          const lForCalculate = this.l_for_calculate && this.l_for_calculate > 0 ? this.l_for_calculate : this.l
          const hForCalculate = this.h_for_calculate && this.h_for_calculate > 0 ? this.h_for_calculate : this.h
          // const checkCanDesign = this.$checkCanFreeDesign(this.mapping_inputs.id)
          const checkCanDesign = [2, 3].includes(this.mapping_inputs.get_template_type)
          const userDesign = await this.$axios.$post('/designs/create-designs', {
            mapping_input_id: patternId,
            w: this.w * 10,
            l: this.l * 10,
            h: this.h * 10,
            s: this.s * 10,
            b: this.b * 10,
            w_for_calculate: wForCalculate * 10,
            l_for_calculate: lForCalculate * 10,
            h_for_calculate: hForCalculate * 10,
            flute,
            select_size_type: this.paperSizeSelected === 'normal' ? 0 : 1,
            design_type: checkCanDesign ? 'normal' : 'upload-drawing',
            r: this.r,
            dim: this.dim,
            paper_id: this.mainPatternActive.paper_id
          })
          this.uuid = userDesign.data.uuid

          await this.$axios.$put('orders/' + result.data.order_id + '/uuid/' + this.uuid)
        }

        localStorage.removeItem('after-login-then')
        this.sendDataLayer(
          'addToCart',
          this.totalResult.factory_id,
          this.$formatPrice(this.totalResult.per_pcs)
        )

        if (result.status === true) {
          this.storeOrder2LocalStorage(null)
          localStorage.removeItem('promoCodePopup')
          const parameters = {
            order_code: result.data.order_code,
            id: result.data.order_id
          }

          if (this.design === false) {
            this.$router.push(this.localeLocation({ name: 'checkout-id', params: parameters }))
          } else {
            this.$router.push(this.localeLocation({
              name: 'brief-design-id',
              params: parameters
            }))
          }
        } else {
          this.hideLoading()
          this.setAlertWithLine({
            show: true,
            message: 'เกิดข้อผิดพลาด ในการสั่งซื้อ',
            header: 'แจ้งเตือน'
          })
        }
      }
    },
    saveZipcode () {
      localStorage.setItem('myZipcode', this.zipCode)
    },
    saveZipcodeEnter () {
      localStorage.setItem('myZipcode', this.zipCode)
      this.$refs.modalMyZipcode.hide()
    },
    async checkPriceFactory (noScrollTo) {
      noScrollTo = typeof noScrollTo !== 'undefined' ? noScrollTo : false
      const w = Number(this.w)
      const l = Number(this.l)
      const h = Number(this.h)
      const product = this.previewProduct[0]
      if (
        !!product.l_must_more_than_w &&
        l > 0 &&
        w > 0 &&
        product.l_must_more_than_w === 1
      ) {
        if (w > l) {
          this.w = l
          this.l = w
        }
      }
      if (
        !!product.l_must_more_than_h &&
        l > 0 &&
        h > 0 &&
        product.l_must_more_than_h === 1
      ) {
        if (h > l) {
          this.setAlertWithLine({
            show: true,
            message: 'ความยาวต้องมากกว่าความสูง',
            header: 'แจ้งเตือน'
          })
          return false
        }
      }

      if (this.design === null) {
        this.setAlert({
          show: true,
          message: 'กรุณาเลือกแบบกราฟฟิค',
          header: 'แจ้งเตือน'
        })
        return false
      }
      if (this.amount === null || this.amount.length === 0) {
        this.$bvModal.show('amount-and-zipcode-warning-modal')
        return false
      }
      if (this.w && this.l && this.h && this.w.length === 0 && this.l.length === 0 && this.h.length === 0) {
      // if (this.w.length === 0 && this.l.length === 0 && this.h.length === 0) {
        this.setAlert({
          show: true,
          message: 'กรุณาระบุขนาดกล่อง',
          header: 'แจ้งเตือน'
        })
        return false
      }
      if (this.papers.length > 0 && this.cardPaperActive.length === 0) {
        this.setAlert({
          show: true,
          message: 'กรุณาเลือกประเภทกระดาษ',
          header: 'แจ้งเตือน'
        })
        return false
      }

      const Zipcode = /^[0-9]{5}$/
      if (
        this.zipCode === null ||
        this.zipCode.length === 0 ||
        Zipcode.test(this.zipCode) === false
      ) {
        this.$refs.modalMyZipcode.show()
        return
      }

      if (this.isAuthenticated) {
        let code = null
        if (this.promoCodeSelect != null && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
          code = this.promoCodeSelect.code
        } else if (this.promoCodeTxt !== '' && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
          code = this.promoCodeTxt
        }
        if (code) {
          this.showLoading()
          try {
            const res = await this.$axios.$get('check-use-promo-code/' + code)
            if (!res.status) {
              this.setAlert({
                show: true,
                message: res.message,
                header: 'แจ้งเตือน'
              })
              this.hideLoading()
              this.promoCodeTxt = ''
              this.promoCodeSelect = null
            }
          } catch (err) {
            this.setAlert({
              show: true,
              message: err.message,
              header: 'แจ้งเตือน'
            })
            this.hideLoading()
            return false
          }
          this.hideLoading()
        }
      }

      // check limit size paper
      let checkLimit = true
      if (
        product.max_w < parseFloat(this.w) ||
        product.min_w > parseFloat(this.w)
      ) {
        checkLimit = false
      }
      if (
        product.max_l < parseFloat(this.l) ||
        product.min_l > parseFloat(this.l)
      ) {
        checkLimit = false
      }
      if (
        this.mapping_inputs.packaging_type_id !== 5 &&
        (product.max_h < parseFloat(this.h) ||
          product.min_h > parseFloat(this.h))
      ) {
        checkLimit = false
      }

      if (
        this.mapping_inputs.packaging_type_id === 2 &&
        parseFloat(this.w) > 999
      ) {
        checkLimit = false
      } else if (
        [1, 3, 5].includes(this.mapping_inputs.packaging_type_id) &&
        (parseFloat(this.w) < parseFloat(product.min_w) || parseFloat(this.w) > 999 ||
          parseFloat(this.l) < parseFloat(product.min_l) || parseFloat(this.l) > 999 ||
          parseFloat(this.h) < parseFloat(product.min_h) || parseFloat(this.h) > 999)
      ) {
        checkLimit = false
      } else if (
        this.mapping_inputs.packaging_type_id === 3 &&
        (parseFloat(this.w) < parseFloat(7.0) ||
          parseFloat(this.l) < parseFloat(8.0) ||
          parseFloat(this.h) < parseFloat(5.0))
      ) {
        checkLimit = false
        this.setAlertWithLine({
          show: true,
          message: 'ขนาดขั้นต่ำ กว้าง 7.0 ซม. x ยาว 8.0 ซม. x สูง 5.0 ซม.',
          header: 'แจ้งเตือน'
        })
        return false
      } else if (this.mapping_inputs.packaging_type_id === 6) {
        let message = ''
        if (parseFloat(this.w) < parseFloat(this.pouchPatternData.min_w)) {
          checkLimit = false
          message += 'ขนาดขั้นต่ำ กว้าง ' + this.pouchPatternData.min_w + ' ซม.'
        } else if (parseFloat(this.w) > parseFloat(this.pouchPatternData.max_w)) {
          checkLimit = false
          message += 'ขนาดสูงสุด กว้าง ' + this.pouchPatternData.max_w + ' ซม.'
        } else if (parseFloat(this.l) < parseFloat(this.pouchPatternData.min_l)) {
          checkLimit = false
          message += 'ขนาดขั้นต่ำ สูง ' + this.pouchPatternData.min_l + ' ซม.'
        } else if (parseFloat(this.l) > parseFloat(this.pouchPatternData.max_l)) {
          checkLimit = false
          message += 'ขนาดสูงสุด สูง ' + this.pouchPatternData.max_l + ' ซม.'
        } else if (this.pouchPatternData.required_b === 1 && (parseFloat(this.b) < parseFloat(this.pouchPatternData.min_b))) {
          checkLimit = false
          message += 'ขนาดขั้นต่ำ ขนาดก้นซองรวมรอยซีล ' + this.pouchPatternData.min_b + ' ซม.'
        } else if (this.pouchPatternData.required_b === 1 && (parseFloat(this.b) > parseFloat(this.pouchPatternData.max_b))) {
          checkLimit = false
          message += 'ขนาดสูงสุด ขนาดก้นซองรวมรอยซีล ' + this.pouchPatternData.max_b + ' ซม.'
        } else if (this.pouchPatternData.required_s === 1 && (parseFloat(this.s) < parseFloat(this.pouchPatternData.min_s))) {
          checkLimit = false
          message += 'ขนาดขั้นต่ำ ขนาดข้างซองรวมรอยซีล ' + this.pouchPatternData.min_s + ' ซม.'
        } else if (this.pouchPatternData.required_s === 1 && (parseFloat(this.s) > parseFloat(this.pouchPatternData.max_s))) {
          checkLimit = false
          message += 'ขนาดสูงสุด ขนาดข้างซองรวมรอยซีล ' + this.pouchPatternData.max_s + ' ซม.'
        }
        if (checkLimit === false) {
          message += `<br><br>ขนาดขั้นต่ำ กว้าง ${this.pouchPatternData.min_w} x ยาว ${this.pouchPatternData.min_l} ${this.pouchPatternData.required_b === 1 ? ' x ขนาดก้นซอง ' + this.pouchPatternData.min_b : ''}${this.pouchPatternData.required_s === 1 ? ' x ขนาดข้างซอง ' + this.pouchPatternData.min_s : ''} ซม.`
          message += `<br>ขนาดสูงสุด กว้าง ${this.pouchPatternData.max_w} x ยาว ${this.pouchPatternData.max_l} ${this.pouchPatternData.required_b === 1 ? ' x ขนาดก้นซอง ' + this.pouchPatternData.max_b : ''}${this.pouchPatternData.required_s === 1 ? ' x ขนาดข้างซอง ' + this.pouchPatternData.max_s : ''} ซม.`
          this.setAlertWithLine({
            show: true,
            message,
            header: 'แจ้งเตือน'
          })
          return false
        }
      }

      if (checkLimit === false) {
        const deepTxtMin = this.h > 0 ? `x สูง ${product.min_h} ซม.` : ''
        this.setAlertWithLine({
          show: true,
          message: `ขนาดขั้นต่ำ กว้าง ${product.min_w} ซม. x ยาว ${product.min_l} ซม. ${deepTxtMin}`,
          header: 'แจ้งเตือน'
        })
        return false
      }

      // LOC-682
      if ([51, 52].includes(this.patternSelected)) {
        if (this.h < this.l / 3) {
          // const minL = (Math.round((this.l / 3) * 100) / 100).toFixed(2)
          const minL = Math.round(((this.l / 3) + Number.EPSILON) * 100) / 100
          this.setAlertWithLine({
            show: true,
            message: `ความสูงต้องมากกว่า ${minL} ซม.`,
            header: 'แจ้งเตือน'
          })
          return
        }
      }

      if (this.packagingTypeId === 3) {
        const flute = await this.$getFlute(this.cardPaperActive)
        if (flute === 'bc') {
          const message = 'กระดาษที่คุณต้องการต้องผลิตด้วยกระบวนการพิเศษ'
          this.setAlertWithLine({
            show: true,
            message,
            header: 'แจ้งเตือน'
          })
          return
        }
      }

      if (this.mapping_inputs.packaging_type_id === 6) {
        this.showEdit = false
        this.prepareData(false)
        this.sendDataLayer('productViewed')

        let rewardData = []
        let discountType = 1
        let promoCode = null
        let firebaseUid = null
        if (this.isAuthenticated) {
          firebaseUid = this.userData.uid
        }
        if (this.promoCodeSelect != null && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
          promoCode = this.promoCodeSelect.code
          discountType = 1
        } else if (this.promoCodeTxt !== '' && (this.discountCodeTemplate === true || localStorage.getItem('discountCodeTemplate') === true)) {
          promoCode = this.promoCodeTxt
          discountType = 1
        } else if (this.reward.points !== '' && (this.redeemPointTemplate === true || localStorage.getItem('redeemPointTemplate') === true)) {
          discountType = 3
          rewardData = this.reward
        }

        const postDataPouch = {
          firebase_uid: firebaseUid,
          pattern_id: this.mapping_inputs.id,
          w: this.w,
          l: this.l,
          s: this.pouchPatternData.required_s === 1 ? this.s : null,
          b: this.pouchPatternData.required_b === 1 ? this.b : null,
          amount: this.amount,
          material_id: this.cardPaperActive,
          micron: this.micronSelected,
          special_technic_id: this.patterns[0].special_technic_id,
          spout: this.spoutId,
          zipcode: this.zipCode,
          promotion_code: promoCode,
          discount_type: discountType,
          reward_data: rewardData,
          is_brief_design: this.design === true ? 1 : 0
        }
        try {
          this.showLoading()
          const { data } = await this.$axios.$post('pouch/calculate', postDataPouch)
          this.hideLoading()
          if (data.length > 0) {
            this.factoryList = data
            this.factoryList.map((o, i) => {
              o.index = i
            })
            this.factoryListOther[0] = data
            if (data[0].min_amount > this.amount) {
              this.amount = data[0].min_amount
              this.setAlert({
                show: true,
                message: `ขนาดที่สั่ง ต้องสั่งซื้อจำนวนขั้นต่ำ ${this.$formatPriceNumber(data[0].min_amount)} ชิ้น`,
                header: 'แจ้งเตือน'
              })
            }
            this.amountOther[0] = this.amount
            if (noScrollTo === false) {
              this.selectFactory(this.factoryList[0])
              this.scrollToCheckPrice(this.$refs.checkPrice)
            }
          }
        } catch (err) {
          this.setAlert({
            show: true,
            message: err.message,
            header: 'แจ้งเตือน'
          })
          this.hideLoading()
        }
      } else {
        // end check limit size paper
        this.showEdit = false
        const postData = this.prepareData(false)
        this.sendDataLayer('productViewed')
        try {
          this.showLoading()
          const result = await this.$axios.$post('products', postData)
          this.hideLoading()
          if ('data' in result && result.data.length > 0) {
            this.factoryList = result.data
            this.factoryList.map((o, i) => {
              o.index = i
            })
            this.factoryListOther[0] = result.data
            this.amountOther[0] = result.amount
            if ('data2' in result) {
              this.factoryListOther[1] = result.data2
              this.amountOther[1] = result.amount2
            }
            if ('data3' in result) {
              this.factoryListOther[2] = result.data3
              this.amountOther[2] = result.amount3
            }
            if (noScrollTo === false) {
              this.selectFactory(this.factoryList[0])
              this.scrollToCheckPrice(this.$refs.checkPrice)
            }
          } else if (this.getOrder2LocalStorage(this.orderKeyRef)) {
            this.storeOrder2LocalStorage(null)
            window.location.reload()
          } else {
            const message = `
              <p>ขนาดที่คุณต้องการต้องผลิตด้วยกระบวนการพิเศษ กรุณาติดต่อเจ้าหน้าที่ line : <a href="https://lin.ee/GODyz91" target="_blank">@locopack</a></p>
              <a href="https://lin.ee/GODyz91" target="_blank"><img class="img-fluid" src="${this.$imgProxy.getUrl('https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/scan_me_line-songkran.png', 496, 496)}"></a>
            `
            this.setAlert({
              show: true,
              message,
              header: 'แจ้งเตือน'
            })
          }
        } catch (err) {
          this.setAlert({
            show: true,
            message: err.message,
            header: 'แจ้งเตือน'
          })
          this.hideLoading()
        }
      }
    },
    sendDataLayer (actionName, factoryId, pricePerPcs) {
      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        let dimension2 = ''
        if (this.design === true) {
          dimension2 = 'จ้างออกแบบ'
        } else if (this.useDesignTool === true) {
          dimension2 = 'ออกแบบออนไลน์'
        } else {
          dimension2 = 'ออกแบบเอง'
        }
        let dimension4 = ''
        if (this.h) {
          dimension4 = `${this.w} x ${this.l} x ${this.h}`
        } else {
          dimension4 = `${this.w} x ${this.l}`
        }
        let dimension9 = ''
        let dimension11 = ''
        if (this.mapping_inputs.packaging_type_id === 3) {
          dimension9 = ''
          dimension11 = this.enamelsLookUp(this.patterns[0].enamel_id)
        } else if (this.mapping_inputs.packaging_type_id === 1) {
          dimension9 = this.enamelsLookUp(this.patterns[0].enamel_id)
          dimension11 = ''
        }
        window.dataLayer.push({
          event: actionName,
          ecommerce: {
            detail: {
              products: [
                {
                  id:
                    `LOCOPACK_${this.mapping_inputs.packaging_type_id}` +
                    this.patternSelected.toString().padStart(9, '0'),
                  name: this.previewProduct[0].name,
                  price: typeof pricePerPcs !== 'undefined' ? pricePerPcs : '',
                  category: this.$pkgTypeThaiName(
                    this.mapping_inputs.packaging_type_id
                  ),
                  brand: 'Locopack',
                  quantity: this.amount,
                  dimension1:
                    typeof factoryId !== 'undefined'
                      ? 'โรงงาน ' + factoryId
                      : '',
                  dimension2,
                  dimension3: this.previewProduct[0].name,
                  dimension4,
                  dimension5: this.cardPaperActiveName,
                  dimension6:
                    this.mapping_inputs.packaging_type_id === 1
                      ? this.paperGramSelected + ''
                      : '',
                  dimension7: this.zipCode,
                  dimension8: this.colorText[this.mapping_inputs.packaging_type_id][this.colorPrintActive],
                  dimension9,
                  dimension10: this.specialTechnicsLookUp(
                    this.patterns[0].special_technic_id
                  ),
                  dimension11,
                  dimension12: '',
                  dimension13: ''
                }
              ]
            }
          }
        })
      } catch (error) {
        this.setAlert({
          show: true,
          message: error.message,
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
      }
    },
    selectFactory (factory, amount) {
      if (typeof amount !== 'undefined') {
        this.amount = amount
      }
      this.factorySelected = factory.factory_id
      this.totalResult = factory
    },
    wldChange () {
      this.drawingData = ''
      this.drawingPNG = ''
      if (this.wldSelected.length > 0) {
        const ar = this.wldSelected.split(',')
        this.w = ar[0]
        this.l = ar[1]
        this.h = ar[2]
      } else {
        this.w = ''
        this.l = ''
        this.h = ''
      }

      if (this.sampleProductData) {
        this.showLoading()
        let productId = ''
        for (const i in this.sampleProductData.releteProductWithStdSize) {
          const relStdSize = this.sampleProductData.releteProductWithStdSize[i]
          if (
            this.wldSelected ===
              `${relStdSize.w},${relStdSize.l},${relStdSize.h}` &&
            productId === ''
          ) {
            productId = relStdSize.product_id
          }
        }
        this.uuid = ''
        this.$router.push(this.localeLocation({
          name: 'customize-product-design-id',
          params: { design: true, id: this.patternSelected },
          query: { productId }
        }))
      }
      if (this.useDesignTool === true) {
        this.getLastDesign()
      }
    },
    showStepModal () {
      this.$bvModal.show('modalStepAmount', {
        hideHeaderClose: false
      })
    },
    productClicked (pattern, indexPT) {
      let category = 'กล่องกระดาษแข็งทั้งหมด'
      if (this.packagingTypeId === 1) {
        category = 'กล่องกระดาษแข็งทั้งหมด'
      } else if (this.packagingTypeId === 2) {
        category = 'สติกเกอร์ทั้งหมด'
      } else if (this.packagingTypeId === 3) {
        category = 'กล่องลูกฟูกทั้งหมด'
      } else if (this.packagingTypeId === 5) {
        category = 'TAG และปลอกทั้งหมด'
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClicked',
        ecommerce: {
          click: {
            actionField: { list: 'สินค้าที่คุณอาจจะชอบ' },
            products: [
              {
                id:
                  `LOCOPACK_SAMPLE_${pattern.sample_product_category_id}` +
                  pattern.id.toString().padStart(9, '0'),
                name: pattern.name,
                price:
                  pattern.price_per_pcs !== null
                    ? pattern.price_per_pcs.toFixed(2)
                    : 0,
                category,
                brand: 'Locopack',
                position: 1,
                dimension1: '',
                dimension2: '',
                dimension3: '',
                dimension4: '',
                dimension5: '',
                dimension6: '',
                dimension7: '',
                dimension8: '',
                dimension9: '',
                dimension10: '',
                dimension11: '',
                dimension12: '',
                dimension13: ''
              }
            ]
          }
        }
      })
    },
    onWaypointRelate ({ going, direction }) {
      if (
        going === this.$waypointMap.GOING_IN &&
        direction === this.$waypointMap.DIRECTION_TOP
      ) {
        const impressions = []
        let category = 'กล่องกระดาษแข็งทั้งหมด'
        if (
          this.relatedProducts.length > 0 &&
          this.waypointReleteLoaded !== true
        ) {
          if (this.packagingTypeId === 1) {
            category = 'กล่องกระดาษแข็งทั้งหมด'
          } else if (this.packagingTypeId === 2) {
            category = 'สติกเกอร์ทั้งหมด'
          } else if (this.packagingTypeId === 3) {
            category = 'กล่องลูกฟูกทั้งหมด'
          } else if (this.packagingTypeId === 5) {
            category = 'TAG และปลอกทั้งหมด'
          }
          for (let k = 0; k < 6; k++) {
            const item = this.relatedProducts[k]
            impressions.push({
              id:
                `LOCOPACK_SAMPLE_${item.sample_product_category_id}` +
                item.id.toString().padStart(9, '0'),
              name: item.name,
              price:
                item.price_per_pcs !== null ? item.price_per_pcs.toFixed(2) : 0,
              category,
              brand: 'Locopack',
              list: 'สินค้าที่คุณอาจจะชอบ',
              position: k + 1,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            })
            this.waypointReleteLoaded = true
          }
        }
        if (impressions.length > 0) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ ecommerce: null })
          window.dataLayer.push({
            event: 'impression',
            ecommerce: {
              impressions
            }
          })
        }
      }
    },
    showModalInfoPaPer () {
      switch (this.mapping_inputs.packaging_type_id) {
        case 3:
          this.showModalInfo('PAPER_TYPE_CORRUGATE')
          break
        case 6:
          this.showModalInfo('MATERIAL_TYPE')
          break
        default:
          this.showModalInfo('PAPER_TYPE')
      }
    },
    showModalInfo (infoTopic) {
      // set modal detail
      this.$refs.modalToolstip.modalDetail = this.guidInfo[infoTopic]
      this.$bvModal.show('attribute_toolstip', {
        hideHeaderClose: false
      })
    },
    async share (totalPrice) {
      this.forShare = {
        params: this.prepareData(),
        factorySelected: this.factorySelected
      }
      const result = await this.$axios.$post('share-products', this.forShare)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'share',
        productName: this.previewProduct[0].name,
        supplier: 'โรงงาน ' + this.factorySelected,
        value: totalPrice
      })
      if (result.status === true) {
        this.$refs.InputAlertModal.msg = result.data.url
        this.$refs.InputAlertModal.show = true
        this.$refs.InputAlertModal.header = 'Share Url'
      } else {
        this.setAlertWithLine({
          show: true,
          message: 'เกิดข้อผิดพลาด',
          header: 'แจ้งเตือน'
        })
      }
    },
    setPaperVideo () {
      this.papers.forEach((paper) => {
        // Folding
        if (paper.id === 12) {
          paper.videoLink = 'https://files.locopack.co/videos/Art300.mp4'
        }
        if (paper.id === 13) {
          paper.videoLink = 'https://files.locopack.co/videos/DP300.mp4'
        }
        if (paper.id === 14) {
          paper.videoLink = 'https://files.locopack.co/videos/DP350.mp4'
        }
        if (paper.id === 15) {
          paper.videoLink = 'https://files.locopack.co/videos/Kraft300.mp4'
        }
        if (paper.id === 95) { // food grade
          paper.videoLink = 'https://files.locopack.co/videos/Art300.mp4'
        }

        // Corrugate
        if (paper.id === 33) {
          paper.videoLink =
            'https://files.locopack.co/videos/BrownCorrugate3.mp4'
        }
        if (paper.id === 34) {
          paper.videoLink =
            'https://files.locopack.co/videos/WhiteBrownCorrugate3.mp4'
        }
        if (paper.id === 35) {
          paper.videoLink =
            'https://files.locopack.co/videos/BrownCorrugate5.mp4'
        }
        if (paper.id === 53) {
          paper.videoLink =
            'https://files.locopack.co/videos/BrownCorrugate3slimV4.mp4'
        }
        if (paper.id === 54) {
          paper.videoLink =
            'https://files.locopack.co/videos/BrownWhiteCorrugate.mp4'
        }
        if (paper.id === 74) {
          paper.videoLink =
            'https://files.locopack.co/videos/WhiteWhite-3layer.mp4'
        }
        if (paper.id === 75) {
          paper.videoLink =
            'https://files.locopack.co/videos/WhiteWhite-3layer-slim.mp4'
        }
      })
    },
    setupEnamelImgCorrugate () {
      this.enamelImgCorrugate = {
        brown: {
          0: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/4.png`
            ],
            area: []
          },
          1: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/4.png`
            ],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1B_B/Area/4.png`
            ]
          },
          4: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/4.png`
            ],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4B_B/Area/4.png`
            ]
          }
        },
        white: {
          0: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/4.png`
            ],
            area: []
          },
          1: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/4.png`
            ],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_B/Area/4.png`
            ]
          },
          4: {
            img: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/4.png`
            ],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_B/Area/4.png`
            ]
          }
        },
        whitewhite: {
          0: {
            img: [],
            area: []
          },
          1: {
            img: [],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_W/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_W/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_W/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_1W_W/Area/4.png`
            ]
          },
          4: {
            img: [],
            area: [
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_W/Area/1.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_W/Area/2.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_W/Area/3.png`,
              `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${this.mappingInputsSubId}_4W_W/Area/4.png`
            ]
          }
        }
      }
      let setDefaultImg = ''
      if (this.packagingTypeId === 3 && this.patterns.length > 1) {
        setDefaultImg = `${this.$store.state.aws_s3}pattern_image/${this.mappingInputsSubId}_${this.paperColor}`
        this.dimension_img = `${this.$store.state.aws_s3}product_dimension/${this.mapping_inputs.id}_${this.paperColor}.png`

        this.patterns.forEach((pattern) => {
          setDefaultImg = `${setDefaultImg}_${pattern.sub_id}_${pattern.color}`
          pattern.pattern_img = `${this.$store.state.aws_s3}pattern_image/${pattern.id}_${pattern.sub_id}_${this.paperColor}.png`
          this.enamelImgCorrugate[pattern.sub_id] = {
            brown: {
              0: {
                img: [`pattern_image/${pattern.sub_id}_brown.png`],
                area: []
              },
              1: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/1/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/1/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/1/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/1/4.png`
                ]
              },
              4: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/4/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/4/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/4/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/brown/4/4.png`
                ]
              }
            },
            white: {
              0: {
                img: [`pattern_image/${pattern.sub_id}_white.png`],
                area: []
              },
              1: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/1/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/1/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/1/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/1/4.png`
                ]
              },
              4: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/4/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/4/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/4/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/white/4/4.png`
                ]
              }
            },
            whitewhite: {
              0: {
                img: [],
                area: []
              },
              1: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/1/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/1/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/1/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/1/4.png`
                ]
              },
              4: {
                img: [],
                area: [
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/4/1.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/4/2.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/4/3.png`,
                  `enamel_sme/CorrugatedBox/${this.mappingInputsSubId}/${pattern.sub_id}/whitewhite/4/4.png`
                ]
              }
            }
          }
        })
        setDefaultImg = `${setDefaultImg}.png`
      }
    },
    async loadOrderDataFromLocalStorage () {
      const orderLocalStorage = this.getOrder2LocalStorage(this.orderKeyRef)
      this.paperSizeSelected = 'custom'
      this.cardPaperActive = orderLocalStorage.params.cardPaperActive
      this.cardPaperActiveName = orderLocalStorage.params.cardPaperActiveName
      this.colorPrintActive = orderLocalStorage.params.colorPrintActive
      this.deliveryDate = orderLocalStorage.params.deliveryDate
      this.w = parseFloat(orderLocalStorage.params.w)
      this.l = parseFloat(orderLocalStorage.params.l)
      this.h = parseFloat(orderLocalStorage.params.h)

      if (orderLocalStorage.params.mapping_inputs.packaging_type_id === 6) {
        this.s = parseFloat(orderLocalStorage.params.s)
        this.b = parseFloat(orderLocalStorage.params.b)
        this.micronSelected = parseFloat(orderLocalStorage.params.micron)
        this.spoutId = parseFloat(orderLocalStorage.params.spout)
      }
      this.amount = await orderLocalStorage.params.amount
      if ('promotion_code' in orderLocalStorage.params) {
        this.promoCodeTxt = orderLocalStorage.params.promotion_code
      }
      this.drawingData = orderLocalStorage.params.drawingData
      this.drawingPNG = orderLocalStorage.params.drawingPNG
      this.zipCode = orderLocalStorage.params.zipcode
      this.design = orderLocalStorage.params.is_brief_design === 1
      if (this.design === true) {
        this.provideTemplate = false
      }
      this.useDesignTool = orderLocalStorage.params.design_by_yourself === 1
      if (this.useDesignTool === true) {
        this.provideTemplate = false
      }
      orderLocalStorage.params.patterns.forEach((pattern, index) => {
        this.patterns[index].pattern_id = pattern.id
        this.patterns[index].enamel_id = pattern.enamel_id
        this.patterns[index].special_technic_id = pattern.special_technic_id
        this.patterns[index].color = pattern.color
      })
      this.changePaperColor()
      this.selectColor()
      this.paperGramSelected = orderLocalStorage.params.paperGramSelected
      this.fromModalDesigner = orderLocalStorage.fromModalDesigner // auth from click modal designer
      if (
        this.w !== '' &&
        this.l !== '' &&
        this.h !== '' &&
        this.wldList.length > 0
      ) {
        this.wldSelected = `${this.w},${this.l},${this.h}`
        for (const k in this.wldList) {
          const wld = this.wldList[k]
          const selectVal = `${wld.w},${wld.l},${wld.h}`
          if (this.wldSelected === selectVal) {
            this.paperSizeSelected = 'normal'
          }
        }
      }
      if (orderLocalStorage.params.mapping_inputs.packaging_type_id === 6) {
        await this.checkPriceFactory(orderLocalStorage.fromModalDesigner === true)
        this.factorySelected = orderLocalStorage.factorySelected
        if (localStorage.getItem('after-login-then') === 'checkout') {
          this.selectFactory(this.factoryList[0])
          this.goToCheckout()
        } else if (localStorage.getItem('after-login-then') === 'opendesigner') {
          this.openModalDesigner()
          localStorage.removeItem('after-login-then')
        }
      }

      if (orderLocalStorage.params.mapping_inputs.packaging_type_id !== 6 && this.zipCode !== '' && this.amount !== '' && this.w !== '' && this.l !== '' && this.h !== '') {
        await this.checkPriceFactory(orderLocalStorage.fromModalDesigner === true)
        this.factorySelected = orderLocalStorage.factorySelected
        if (localStorage.getItem('after-login-then') === 'checkout' && this.factorySelected) {
          if (this.factoryList && this.factoryList.length > 0) {
            for (const f in this.factoryList) {
              if (this.factoryList[f].factory_id === this.factorySelected) {
                this.selectFactory(this.factoryList[f])
              }
            }
          }
          this.goToCheckout()
        } else if (localStorage.getItem('after-login-then') === 'opendesigner') {
          this.openModalDesigner()
          localStorage.removeItem('after-login-then')
        }
      }
    },
    showpopup () {
      const promoCodePopupStorage = JSON.parse(
        localStorage.getItem('promoCodePopupStorage')
      )
      if (promoCodePopupStorage) {
        const now = new Date()
        if (now.getTime() <= promoCodePopupStorage) {
          return
        } else {
          // 10800000 = 3 ชั่วโมง
          localStorage.setItem(
            'promoCodePopupStorage',
            JSON.stringify(new Date().getTime() + 10800000)
          )
        }
      } else {
        // 10800000 = 3 ชั่วโมง
        localStorage.setItem(
          'promoCodePopupStorage',
          JSON.stringify(new Date().getTime() + 10800000)
        )
      }
      if (this.promoCodePopup.code && this.$refs['modal-promotion-popup']) {
        this.$refs['modal-promotion-popup'].show()
        setTimeout(() => {
          this.$refs['modal-promotion-popup'].hide()
        }, 2000)
      }
    },
    async savePromoCode (code, name) {
      if (this.isAuthenticated) {
        this.showLoading()
        try {
          await this.$axios.$post('save-promo-code', { code })
          this.hideLoading()
        } catch (err) {
          this.setAlert({
            show: true,
            message: err.message,
            header: 'แจ้งเตือน'
          })
          this.hideLoading()
        }
        try {
          this.$axios.$get('get-user-promo-codes').then((res) => {
            const codeArray = []
            for (const item of res) {
              codeArray.push({ code: item.code, name: item.name })
            }
            this.promoCode = codeArray
            if (this.promoCode && this.promoCode.length > 0) {
              this.promoCodeSelect = this.promoCode[0]
            }
          })
        } catch (err) {
          this.setAlert({
            show: true,
            message: err.message,
            header: 'แจ้งเตือน'
          })
          this.hideLoading()
        }
        this.hideLoading()
      } else {
        let promoCode = JSON.parse(localStorage.getItem('promoCode')) || []
        if (promoCode) {
          // ปรับให้ code popup เก็บไว้แค่อันเดียวพอ
          const codePopup = promoCode.filter(o => o.code === code)
          if (codePopup.length === 0) {
            promoCode.push({ code, name })
          }
        } else {
          promoCode = [{ code, name }]
        }
        this.promoCode = promoCode
        localStorage.setItem('promoCode', JSON.stringify(promoCode))
      }
      this.setAlert({
        show: true,
        message: 'บันทึกโค้ดส่วนลด: ' + code + ' เรียบร้อยแล้ว',
        header: 'Save Success'
      })
      this.$refs['modal-promotion-popup'].hide()
      setTimeout(() => {
        this.$store.commit('setAlert', { show: false, message: '' })
      }, 3000)
    },
    openLinkNewTab (link) {
      window.open(link, '_blank')
    },
    getYoutubeThumbnail (link) {
      const splitLink = link.split('/')
      return 'https://img.youtube.com/vi/' + splitLink[4] + '/hqdefault.jpg'
    },
    retColorTxt (pkgId, color) {
      // colorText[mapping_inputs.packaging_type_id][colorPrintActive]
      return this.colorText[pkgId][color]
    },
    selectSpecialTechnic (i, id) {
      if (this.amount < 1000) {
        this.setAlertWithLine({
          show: true,
          message: 'จำนวนสั่งขั้นต่ำ 1,000 ชิ้น เมื่อเลือกเทคนิคพิเศษ',
          header: 'แจ้งเตือน'
        })
        if (this.patterns[i].special_technic_id.find(element => element === id)) {
          const index = this.patterns[i].special_technic_id.indexOf(id)
          if (index !== -1) {
            this.patterns[i].special_technic_id.splice(index, 1)
          }
        }
      }
    },
    disabledSpecialTech (i, action) {
      if (this.mapping_inputs.packaging_type_id === 6) {
        if (action === 'spout' && this.spoutId) {
          const checkZip = this.patterns[i].special_technic_id.indexOf(89)
          if (checkZip !== -1) {
            this.patterns[i].special_technic_id.splice(checkZip, 1)
          }
          const checkDegassing = this.patterns[i].special_technic_id.indexOf(90)
          if (checkDegassing !== -1) {
            this.patterns[i].special_technic_id.splice(checkDegassing, 1)
          }
        } else if (action === 'special_technic' && (this.patterns[i].special_technic_id.find(o => o === 89) || this.patterns[i].special_technic_id.find(o => o === 90))) {
          this.spoutId = null
        }
      }
    }
  },

  head () {
    return {
      title: this.seo.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.seo.description
        },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords },
        { hid: 'og:title', property: 'og:title', content: this.seo.title },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seo.description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          // content: null,
          content: (this.previewProduct && typeof this.previewProduct[0].pattern_img !== 'undefined' && this.previewProduct[0].pattern_img.length !== 0 && typeof this.previewProduct[0].pattern_img[0].path !== 'undefined') ? this.$imgProxy.getUrl(
            this.$store.state.aws_s3 +
              this.previewProduct[0].pattern_img[0].path,
            640,
            0
          ) : ''
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ],
      script: [
        { src: 'https://static.line-scdn.net/liff/edge/2/sdk.js'}
      ]
    }
  }
}
